<div style="min-height: 100vh;" class="custom-container">
    <div *ngIf="!isEditing" class="col-lg-7 pt-5 offset-lg-3 mt-5">
        <div class="col-12">
            <div class="row">
                <div class="col-md-4">
                    <input required="" [(ngModel)]="titleFilter" (ngModelChange)="resetPageNumber()" class="form-control mb-5" placeholder="Filtrar por descripción corta" type="text">
                </div>
                <div class="col-md-4">
                    <input required="" [(ngModel)]="idFilter" (ngModelChange)="resetPageNumber()" class="form-control mb-5" placeholder="Filtrar por ID" type="text">
                </div>
                <div class="col-md-4">
                    <input required="" [(ngModel)]="tradeMarkFilter" (ngModelChange)="resetPageNumber()" class="form-control mb-5" placeholder="Filtrar por marca" type="text">
                </div>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th >ID</th>
                            <th >Título</th>
                            <th >Descripción corta</th>
                            <th >Precio</th>
                            <th >Estado</th>
                            <th >Stock</th>
                            <th >Stock crítico</th>
                            <th >Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let product of products | descriptionFilter:titleFilter |idFilter:idFilter | trademarkFilter:tradeMarkFilter | paginate: { itemsPerPage: 25, currentPage: p }; index as i">
                            <td class="product-thumbnail">{{product.id}}</td>
                            <td class="product-thumbnail">{{product.title}}</td>
                            <td class="product-name" data-title="Product">{{product.description}}</td>
                            <td class="product-price" data-title="Price">${{product.price}}</td>
                            <td *ngIf="product.published" class="product-stock-status" data-title="Stock Status"><span class="badge badge-pill badge-success">Publicado</span></td>
                            <td *ngIf="!product.published" class="product-stock-status" data-title="Stock Status"><span class="badge badge-pill badge-danger">No publicado</span></td>
                            <td class="product-price" data-title="Price">{{product.stock}}</td>
                            <td class="product-price" data-title="Price">{{product.minStock}}</td>
                            <td class="product-remove" data-title="Remove"><a class="edit" (click)="edit(product, i)" href="javascript:void(0)">Editar</a><a class="delete" (click)="deleteProduct(product)" href="javascrip:void(0)">Borrar</a></td>
                        </tr>
                    </tbody>
                </table>
                <div class="row justify-content-center mb-3">
                    <pagination-controls 
                        class="center"
                        (pageChange)="p = $event"
                        nextLabel="Siguiente"
                        previousLabel="Anterior"
                        responsive="true"
                        autoHide="true"
                    >
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isEditing&&selectedProduct" class="col-lg-7 offset-lg-3">
        <h2 class="mt-5 mb-5">Actualizar producto</h2>
        <form >
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" autocomplete="off" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.title" class="form-control" name="title" placeholder="Título">
                        <label for="title">Título</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" readonly autocomplete="off" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.id" class="form-control" name="id" placeholder="ID">
                        <label for="id">ID</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <textarea rows="5" readonly autocomplete="off" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.description" class="form-control" name="description" placeholder="Descripción corta"></textarea>
                        <label for="title">Descripción corta</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <textarea rows="5" autocomplete="off" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.longDescription" class="form-control" name="longDescription" placeholder="Descripción larga"></textarea>
                        <label for="title">Descripción larga</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="number" readonly [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.price" class="form-control" name="price" placeholder="Precio">
                        <label for="title">Precio</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="number" readonly [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.stock" class="form-control" name="stock" placeholder="Stock">
                        <label for="title">Stock</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="number" readonly [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.minStock" class="form-control" name="minStock" placeholder="Stock crítico">
                        <label for="title">Stock crítico</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" autocomplete="off" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.videoUrl" class="form-control" name="video" placeholder="You Tube Url">
                        <label for="title">Video Url</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="number" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.index" class="form-control" name="index" placeholder="Índice">
                        <label for="title">Índice</label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" autocomplete="off" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.tradeMark" class="form-control" name="trademark" placeholder="Marca">
                        <label for="title">Marca</label>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="form-group">
                        <input [readOnly]="!selectedProduct.cluster" autocomplete="off" type="text" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.clusterName" class="form-control" name="clusterName" placeholder="Nombre de agrupación">
                        <label for="title">Nombre de la agrupación</label>
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="form-group">
                        <input [readOnly]="!selectedProduct.cluster" autocomplete="off" type="text" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.clusterSelector" class="form-control" name="clusterName" placeholder="Selector de agrupación">
                        <label for="title">Selector de agrupación</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.cluster" type="checkbox" name="cluster" id="cluster">
                                <label class="form-check-label label_info" for="cluster"><span>Producto agrupado</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.visible" type="checkbox" name="visible" id="visible">
                                <label class="form-check-label label_info" for="visible"><span>Visible</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.published" type="checkbox" name="published" id="published">
                                <label class="form-check-label label_info" for="published"><span>Publicado</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedProduct.clusterContainer" type="checkbox" name="container" id="container">
                                <label class="form-check-label label_info" for="container"><span>Contenedor</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3">
                    <div class="form-group form-floating">
                        <input #f1 type="file" id="f1" (change)="getFile($event,1)" class="form-control"  name="image" placeholder="Imágen">
                        <label for="file">Foto principal(540x600)</label>
                    </div>
                </div>

                <div class="col-md-4 mb-3">
                    <div class="form-group form-floating">
                        <input #f2 type="file" id="f2" (change)="getFile($event,2)" class="form-control"  name="image" placeholder="Imágen">
                        <label for="file">Foto 2(540x600)</label>
                    </div>
                </div>

                <div class="col-md-4 mb-3">
                    <div class="form-group form-floating">
                        <input #f3 type="file" id="f3" (change)="getFile($event,3)" class="form-control"  name="image" placeholder="Imágen">
                        <label for="file">Foto 3(540x600)</label>
                    </div>
                </div>

                <div class="col-md-4">
                    <div *ngIf="selectedProduct.imageUrl!='no-image'">
                        <img [src]="selectedProduct.imageUrl" alt="" width="70%">
                        <div *ngIf="isUploading" class="spinner-border text-success" role="status">
                            <span class="sr-only">Cargando...</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div *ngIf="selectedProduct.imageUrl2!='no-image'">
                        <img [src]="selectedProduct.imageUrl2" alt="" width="70%">
                        <div *ngIf="isUploading" class="spinner-border text-success" role="status">
                            <span class="sr-only">Cargando...</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div *ngIf="selectedProduct.imageUrl3!='no-image'">
                        <img [src]="selectedProduct.imageUrl3" alt="" width="70%">
                        <div *ngIf="isUploading" class="spinner-border text-success" role="status">
                            <span class="sr-only">Cargando...</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-4">
                                <input type="text" [(ngModel)]="tag" [ngModelOptions]="{standalone:true}" class="form-control" name="tags" placeholder="Etiqueta" autocomplete="off">
                            </div>
                            <div class="col-4">
                                <input type="text" [(ngModel)]="tagValue" [ngModelOptions]="{standalone:true}" class="form-control" name="tagValue" placeholder="Valor" autocomplete="off">
                            </div>
                            <div class="col-4">
                                <button type="button" class="btn btn-dark btn-radius" (click)="addTag()" name="submit" value="Submit">Agregar</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedProduct.tags.length>0" style="margin-bottom: 10px;">
                        <span class="erasable-span" *ngFor="let tag of selectedProduct.tags; index as i" (click)="deleteTag(i)">{{tag.tag}}:{{tag.value}} </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-8">
                                <select [(ngModel)]="category" [ngModelOptions]="{standalone:true}" class="form-control">
                                    <option disabled value="">Agregar categorías</option>
                                    <option *ngFor="let category of categories" [value]="category.title">{{category.title}}</option>
                                </select>
                            </div>
                            <div class="col-4">
                                <button type="button" class="btn btn-dark btn-radius" (click)="addCategory()" name="submit" value="Submit">Agregar</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedProduct.categories.length>0" style="margin-bottom: 10px;">
                        <span class="erasable-span" *ngFor="let category of selectedProduct.categories; index as i" (click)="deleteCategory(i)">{{category}} </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-8">
                                <input type="text" [(ngModel)]="related" [ngModelOptions]="{standalone:true}" class="form-control" name="categories" placeholder="ID's relacionados" autocomplete="off">
                            </div>
                            <div class="col-4">
                                <button type="button" class="btn btn-dark btn-radius" (click)="addRelated()" name="submit" value="Submit">Agregar</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="selectedProduct.related.length>0" style="margin-bottom: 10px;">
                        <span class="erasable-span" *ngFor="let related of selectedProduct.related; index as i" (click)="deleteRelated(i)">{{related}} </span>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="NgxEditor__Wrapper">
                        <ngx-editor-menu [editor]="editor"> </ngx-editor-menu>
                        <ngx-editor
                          [editor]="editor"
                          [(ngModel)]="html"
                          [ngModelOptions]="{standalone:true}"
                          [editable]="true"
                          [placeholder]="'...'"
                        ></ngx-editor>
                      </div>
                </div>

                <div class="col-md-12 mb-5 mt-5">
                    <button type="button" class="btn btn-dark btn-radius" (click)="isEditing=false; selectedProduct=undefined" name="submit" value="Submit">Vovler al listado</button>
                    <button type="button" (click)="previous()" class="btn btn-light btn-radius pull-right" name="next" value="Submit">Anterior</button>
                    <button type="button" (click)="next()" class="btn btn-info btn-radius pull-right" name="prev" value="Submit">Siguiente</button>
                    <a href="javascript:void(0)" (click)="update()" class="btn btn-fill-out btn-radius text-uppercase mr-30">Actualizar</a>

                </div>
            </div>
        </form>
    </div>
</div>