<div *ngIf="!isEditing" style="min-height: 100vh;" class="custom-container">
    <div class="col-lg-7 pt-5 offset-lg-3 mt-5">
        <div class="col-12">
            <div class="row">
                <div class="col-md-4">
                    <input required="" [(ngModel)]="userFilter" class="form-control mb-5" placeholder="Filtrar por nombre de cliente" type="text">
                </div>
            </div>
            <div *ngIf="users.length>0" class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th >Cliente</th>
                            <th >Teléfono</th>
                            <th >Email</th>
                            <th >id</th>
                            <th >Cuenta Cte</th>
                            <th >Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let user of users | user:userFilter | paginate: { itemsPerPage: 25, currentPage: p }; index as i">
                            <td >{{user.userName}}</td>
                            <td >{{user.phone}}</td>
                            <td >{{user.email}}</td>
                            <td >{{user.id}}</td>
                            <td >{{user.cte?"Si":"No"}}</td>
                            <td class="product-remove" data-title="Remove">
                                <a class="delete" (click)="deleteUser(user)" href="javascript:void(0)">Borrar</a><span> / </span>
                                <a class="delete" (click)="editUser(user)" href="javascript:void(0)">Editar usuario</a><span> / </span>
                                <a class="delete" [routerLink]="['../orders']" [queryParams]="{id:user._id}" href="javascript:void(0)">Ver pedidos</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row justify-content-center mb-3">
                    <pagination-controls 
                        class="center"
                        (pageChange)="p = $event"
                        nextLabel="Siguiente"
                        previousLabel="Anterior"
                        responsive="true"
                        autoHide="true"
                    >
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>
<div style="min-height: 100vh;" class="custom-container">
    <div *ngIf="isEditing&&selectedUser" class="col-lg-7 pt-5 offset-lg-3 mt-5">
        <div  class="card">
            <div class="card-header">
                <h3>Detalles de la cuenta</h3>
            </div>
            <div class="card-body">
                <form name="enq">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label>Nombre y apellido</label>
                            <input class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedUser.userName" name="name" type="text">
                         </div>
                         <div class="form-group col-md-12">
                            <label>Email </label>
                            <input  class="form-control" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedUser.email" name="email">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Teléfono</label>
                            <input [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedUser.phone" class="form-control" name="dname" type="text">
                        </div>
                        <div class="form-group col-md-6">
                            <label>ID</label>
                            <input [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedUser.id" class="form-control" name="dname" type="text">
                        </div>
                        <div class="form-group col-md-6">
                            <label>Rol</label>
                            <select [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedUser.role" class="form-control" name="dname" type="text">
                                <option value="admin">Administrador</option>
                                <option value="user">Usuario</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Nueva contraseña</label>
                            <input [(ngModel)]="newPassword" [ngModelOptions]="{standalone:true}" class="form-control" name="dname" type="password">
                            <button (click)="updatePassword()" class="btn btn-sm btn-warning float-right mt-1">Actualizar contraseña</button>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <div class="chek-form">
                                    <div class="custome-checkbox">
                                        <input class="form-check-input" [ngModelOptions]="{standalone:true}" [(ngModel)]="selectedUser.cte" type="checkbox" name="cte" id="cte">
                                        <label class="form-check-label label_info" for="cte"><span>Cuenta corriente</span></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button type="button" class="btn btn-dark btn-radius" (click)="isEditing=false; selectedUser=undefined" name="submit" value="Submit">Atrás</button>
                            <button type="button" class="btn btn-fill-out" (click)="update()" name="submit" value="Submit">Guardar</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
