<div *ngIf="mainBannerProducts">

<!-- START SECTION BANNER -->
<div class="mt-4 staggered-animation-wrap">
	<div class="custom-container">
    	<div class="row">
        	<div class="col-lg-7 offset-lg-3">
            	<div class="banner_section shop_el_slider">
                    <div id="carouselExampleControls" class="carousel slide carousel-fade light_arrow" data-ride="carousel">
                        <div class="carousel-inner">
                            <div *ngFor="let product of mainBannerProducts; index as i" style="background-position: center center !important; background-size: contain !important; background-repeat: no-repeat !important;" class="carousel-item background_bg" [ngClass]="i==0?'active':''" data-img-src="https://i.imgur.com/1XdYOIJ.jpg" [ngStyle]="{'background-image':'url('+product.image+')'}">
                                <div class="banner_slide_content banner_content_inner">
                                    <div class="col-lg-7 col-10">
                                        <div class="banner_content3 overflow-hidden">
                                            <h5 class="mb-3 staggered-animation font-weight-light" data-animation="slideInLeft" data-animation-delay="0.5s">{{product.description}}</h5>
                                            <h2 class="staggered-animation" data-animation="slideInLeft" data-animation-delay="1s">{{product.title}}</h2>
                                            <!-- <h4 *ngIf="!product.category" class="staggered-animation mb-4 product-price" data-animation="slideInLeft" data-animation-delay="1.2s"><span class="price">${{product.price}}</span></h4> -->
                                            <a class="btn btn-fill-out btn-radius staggered-animation text-uppercase" href="javascript:void(0)" (click)="handleNavigate(product)" data-animation="slideInLeft" data-animation-delay="1.5s">Ver</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ol class="carousel-indicators indicators_style3">
                            <li *ngFor="let p of mainBannerProducts; index as i" data-target="#carouselExampleControls" attr.data-slide-to="{{i}}" [ngClass]="i==0?'active':''"></li>
                        </ol>
                    </div>
                </div>
            </div>


            <div class="col-lg-2 d-none d-lg-block">
            	<div *ngFor="let product of sideMainBanner" class="shop_banner2" [ngStyle]="{'background-image':'url('+product.image+')'}">
                	<a href="javascript:void(0)" (click)="handleNavigate(product)" class="hover_effect1">
                    	<div class="el_title">
                            <span>{{product.title.toUpperCase()}}</span>
                        </div>
                    </a>
                </div>
            </div>


        </div>
    </div>
</div>
<!-- END SECTION BANNER -->

<div class="section small_pt pb-0">
	<div class="custom-container">
    	<div class="row">
        	<div class="col-xl-3 d-none d-xl-block">
            	<div class="sale-banner">
                	<a class="hover_effect1" (click)="handleNavigate(singlePromo)" href="javascript:void(0)">
                        <div class="el_title">
                            <span>{{singlePromo.title.toUpperCase()}}</span>
                        </div>
                		<img [src]="singlePromo.image" alt="shop_banner_img6">
                    </a>
                </div>
            </div>
        	<div class="col-xl-9">
                <div class="row">
                    <div class="col-12">
                        <div class="heading_tab_header">
                            <div class="heading_s2">
                                <h4>Nuestros productos</h4>
                            </div>
                            <div class="tab-style2">
                                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#tabmenubar" aria-expanded="false"> 
                                    <span class="ion-android-menu"></span>
                                </button>
                                <ul class="nav nav-tabs justify-content-center justify-content-md-end" id="tabmenubar" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="arrival-tab" data-toggle="tab" href="#arrival" role="tab" aria-controls="arrival" aria-selected="true">Nuevos productos</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="sellers-tab" data-toggle="tab" href="#sellers" role="tab" aria-controls="sellers" aria-selected="false">Destacados</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="tab_slider">
                            <div class="tab-pane fade show active" id="arrival" role="tabpanel" aria-labelledby="arrival-tab">
                                <div class="product_slider carousel_slider owl-carousel owl-theme dot_style1" data-loop="true" data-margin="20" data-responsive='{"0":{"items": "1"}, "481":{"items": "2"}, "768":{"items": "3"}, "991":{"items": "4"}}'>
                                    <div *ngFor="let product of newProducts" class="item">
                                        <div class="product_wrap">
                                            <div class="product_img">
                                                <a href="javascript:void(0)" [routerLink]="'product/'+product.id">
                                                    <img [src]="product.image" alt="el_img1">
                                                    <img *ngIf="product.imageHover" class="product_hover_img" [src]="product.imageHover" alt="el_hover_img1">
                                                </a>
                                                <div class="product_action_box">
                                                    <ul class="list_none pr_action_btn">
                                                        <li><a href="javascript:void(0)" [routerLink]="'product/'+product.id" class="popup-ajax"><i class="lnr lnr-eye"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="product_info">
                                                <h6 class="product_title"><a [routerLink]="'product/'+product.id" href="javascript:void(0)">{{product.title}}</a></h6>
                                                <div class="product_price">
                                                    <span *ngIf="product.minStock>0" class="price">${{product.price}}</span>
                                                </div>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="sellers" role="tabpanel" aria-labelledby="sellers-tab">
                                <div class="product_slider carousel_slider owl-carousel owl-theme dot_style1" data-loop="true" data-margin="20" data-responsive='{"0":{"items": "1"}, "481":{"items": "2"}, "768":{"items": "3"}, "991":{"items": "4"}}'>
                                    <div *ngFor="let product of featuredProducts" class="item">
                                        <div class="product_wrap">
                                            <div class="product_img">
                                                <a href="javascript:void(0)" [routerLink]="'product/'+product.id">
                                                    <img [src]="product.image" alt="el_img1">
                                                    <img *ngIf="product.imageHover" class="product_hover_img" [src]="product.imageHover" alt="el_hover_img1">
                                                </a>
                                                <div class="product_action_box">
                                                    <ul class="list_none pr_action_btn">
                                                        <li><a href="javascript:void(0)" [routerLink]="'product/'+product.id" class="popup-ajax"><i class="lnr lnr-eye"></i></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="product_info">
                                                <h6 class="product_title"><a href="javascript:void(0)" [routerLink]="'product/'+product.id">{{product.title}}</a></h6>
                                                <div class="product_price">
                                                    <span *ngIf="product.minStock>0" class="price">${{product.price}}</span>
                                                </div>
                                              </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        	</div>
        </div>
    </div>
</div>


<!-- START SECTION BANNER --> 
<div class="section pb_20 small_pt">
	<div class="custom-container">
    	<div class="row">
        	<div *ngFor="let product of lastMainBanner" class="col-md-4">
            	<div class="sale-banner mb-3 mb-md-4">
                	<a class="hover_effect1" href="javascript:void(0)">
                		<img [src]="product.image" width="540" height="300" style="object-fit: cover;" alt="shop_banner_img7">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END SECTION BANNER --> 


<!-- START SECTION CLIENT LOGO -->
<div class="section pt-0 small_pb">
	<div class="custom-container">
    	<div class="row">
			<div class="col-md-12">
            	<div class="heading_tab_header">
                    <div class="heading_s2">
                        <h4>Nuestras marcas</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
        	<div class="col-12">
            	<div class="client_logo carousel_slider owl-carousel owl-theme nav_style3" data-dots="false" data-nav="true" data-margin="30" data-loop="true" data-autoplay="true" data-responsive='{"0":{"items": "2"}, "480":{"items": "3"}, "767":{"items": "4"}, "991":{"items": "5"}, "1199":{"items": "6"}}'>
                	<div *ngFor="let logo of logos" class="item">
                    	    <div class="cl_logo">
                                <img [src]="logo.image" alt="cl_logo"/>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END SECTION CLIENT LOGO -->
</div>


<div class="modal fade" id="qrModal" tabindex="-1" role="dialog" aria-labelledby="loginModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Completá el formulario</h3>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="col-12 p-5">
                <form [formGroup]="raffleForm">
                    <div class="form-group">
                        <input class="form-control" formControlName="name" required="" type="text" placeholder="Nombre y apellido">
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName="email" required="" class="form-control" placeholder="Email">
                    </div>
                    <div class="form-group">
                        <input class="form-control" formControlName="dni" required="" type="text" placeholder="DNI">
                    </div>
                    <div class="form-group">
                        <input class="form-control" formControlName="phone" required="" type="text" placeholder="Celular">
                    </div>
                    <div class="form-group">
                        <button [disabled]="!raffleForm.valid" type="button" (click)="registerRaffle()" class="btn btn-fill-out btn-block" name="login">Registrarme</button>
                    </div>
                </form>
            </div>
        </div>
      </div>
    </div>
  </div>