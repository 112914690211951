import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './pages/about/about.component';
import { BannersConfigComponent } from './pages/banners-config/banners-config.component';
import { CardsComponent } from './pages/cards/cards.component';
import { CategoriesConfigComponent } from './pages/categories-config/categories-config.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { CreateProductComponent } from './pages/create-product/create-product.component';
import { EditProductsComponent } from './pages/edit-products/edit-products.component';
import { MainComponent } from './pages/main/main.component';
import { MyAccountComponent } from './pages/myAccount/my-account.component';
import { OrdersComponent } from './pages/orders/orders/orders.component';
import { ProductComponent } from './pages/product/product.component';
import { QrGeneratorComponent } from './pages/qr-generator/qr-generator.component';
import { RegisterComponent } from './pages/register/register.component';
import { RestoreComponent } from './pages/restore/restore.component';
import { ShippingValuesComponent } from './pages/shippingValues/shipping-values.component';
import { UsersComponent } from './pages/users/users.component';
import { ViewProductsComponent } from './pages/view-products/view-products.component';

const routes: Routes = [
  {path:'', component: MainComponent, data:{title:'GM sanitarios'}},
  {path:'createProduct', component:CreateProductComponent, data:{title:'GM sanitarios'}},
  {path:'editBanners', component:BannersConfigComponent, data:{title:'GM sanitarios'}},
  {path:'editCategories', component:CategoriesConfigComponent, data:{title:'GM sanitarios'}},
  {path:'viewProducts', component:ViewProductsComponent, data:{title:'GM sanitarios'}},
  {path:'editProducts', component:EditProductsComponent, data:{title:'GM sanitarios'}},
  {path:'product/:id', component:ProductComponent},
  {path:'checkout', component:CheckoutComponent, data:{title:'GM sanitarios'}},
  {path:'register', component:RegisterComponent, data:{title:'GM sanitarios'}},
  {path:'myAccount', component:MyAccountComponent, data:{title:'GM sanitarios'}},
  {path:'shippingConfig', component:ShippingValuesComponent, data:{title:'GM sanitarios'}},
  {path:'orders', component:OrdersComponent, data:{title:'GM sanitarios'}},
  {path:'users', component:UsersComponent, data:{title:'GM sanitarios'}},
  {path:'cards', component:CardsComponent, data:{title:'GM sanitarios'}},
  {path:'restore', component:RestoreComponent, data:{title:'GM sanitarios'}},
  {path:'about', component:AboutComponent, data:{title:'GM sanitarios'}},
  {path:'generator', component:QrGeneratorComponent, data:{title:'GM sanitarios'}},
  {path:'**', redirectTo:'/', pathMatch:'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
