import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'trademarkFilter'
})
export class TrademarkFilterPipe implements PipeTransform {

  transform(value: any, arg: String): any {

    let response = [];
    if (!arg||arg.length==0) response = value;
    else{
      value.map(
        producto=>{
          if(producto.tradeMark.toUpperCase().indexOf(arg.toUpperCase())>=0) response.push(producto);
        }
      )
    }
    return response;
  }
}
