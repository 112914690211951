import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http:HttpClient
  ) { }

    createProduct(data):Observable<any>{
      return this.http.post(`${environment.apiBaseUrl}/products/createProduct`, data);
    }

    updateProduct(data):Observable<any>{
      return this.http.put(`${environment.apiBaseUrl}/products/updateProduct`, data);
    }

    deleteProduct(data):Observable<any>{
      return this.http.post(`${environment.apiBaseUrl}/products/deleteProduct`, data);
    }

    getProductsByCategory(data):Observable<any>{
      return this.http.get(`${environment.apiBaseUrl}/products/getProductsByCategory?category=${data}`);
    }

    getProductsByTrademark(data):Observable<any>{
      return this.http.get(`${environment.apiBaseUrl}/products/getProductsByTrademark?category=${data}`);
    }

    getProducts():Observable<any>{
      return this.http.get(`${environment.apiBaseUrl}/products/getProducts`);
    }

    getProductByInternalId(id):Observable<any>{
      return this.http.get(`${environment.apiBaseUrl}/products/getProductByInternalId?id=${id}`);
    }

    getProductById(id):Observable<any>{
      return this.http.get(`${environment.apiBaseUrl}/products/getProductById?_id=${id}`);
    }

    getProductsByText(text):Observable<any>{
      return this.http.get(`${environment.apiBaseUrl}/products/getProductsByText?text=${text}`);
    }

    updateBanner(data):Observable<any>{
      return this.http.post(`${environment.apiBaseUrl}/products/updateBanners`, data);
    }

    getBannerConfig():Observable<any>{
      return this.http.get(`${environment.apiBaseUrl}/products/getBannerConfig`);
    }

    updateCategories(data):Observable<any>{
      return this.http.post(`${environment.apiBaseUrl}/products/updateCategories`, data);
    }

    getCategories():Observable<any>{
      return this.http.get(`${environment.apiBaseUrl}/products/getCategories`);
    }

    getRelatedProducts(data):Observable<any>{
      return this.http.post(`${environment.apiBaseUrl}/products/getRelatedProducts`, data);
    }

    getProductsByCluster(cluster):Observable<any>{
      return this.http.get(`${environment.apiBaseUrl}/products/getProductsByCluster?cluster=${cluster}`);
    }

    getProductsByIds(data):Observable<any>{
      return this.http.post(`${environment.apiBaseUrl}/products/getProductsByIds`, data);
    }

}
