import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { Component, ElementRef, OnInit, ViewChild , PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';
declare var $:any;

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {

  @ViewChild('info') info:ElementRef;

  public product;
  public selectedPhoto;
  public related = [];
  public qnt = 1;
  public clusterSelector="";
  public productsOnCluster = [];
  public environment = environment;
  public cards = [];

  constructor(
    private cartService:CartService,
    private route:ActivatedRoute,
    private productService:ProductService,
	private router:Router,
	private orderService:OrderService,
	private titleService: Title,
	private meta:Meta,
    @Inject(PLATFORM_ID) private platformId

  ) { 
	this.related = [];
    this.route.params.subscribe(
      resp=>{
        let id = resp.id;
        this.productService.getProductByInternalId(id).subscribe(
          resp=>{
			if(resp){
				this.product = resp;
				this.meta.updateTag({property:'og:title', content:resp.title});
				this.meta.updateTag({property:'og:description', content:resp.longDescription});
				this.meta.updateTag({property:'og:image', content:resp.imageUrl});
				this.meta.updateTag({property:'og:url', content:`https://gmsanitarios.com${this.router.url}`});
				this.titleService.setTitle(resp.title);
				setTimeout(()=>{
					this.info.nativeElement.innerHTML = this.product.info;
				},500);
				if (this.product.related.length>0){
				  this.productService.getRelatedProducts({related:this.product.related}).subscribe(
					rel=>{
					  this.related = rel;
					  setTimeout(()=>{
						  this.carousel_slider();
					  }, 30);
					},
					err=>console.log(err)
				  )
				}
				if(this.product.cluster){
					this.productService.getProductsByCluster(this.product.clusterName).subscribe(
						resp=>{
							this.productsOnCluster = resp;
							this.productsOnCluster.sort(function(a,b){
								return a.id-b.id;
							})
						},
						err=>console.log(err)
					)
				}
				this.selectedPhoto = this.product.imageUrl;
				setTimeout(()=>{
				  //initLoad();
				  this.slick_slider();
				},30)
			}
          },
          err=>console.log(err)
        )
      },
      err=>console.log(err)
    )
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)){
		scrollTo(0,0)
	}
	this.orderService.getCreditCards().subscribe(
		resp=>{
			this.cards = resp;
		},
		err=>console.log(err)
	)
  }

  slick_slider() {

			$('.slick_slider').each( function() {
				var $slick_carousel = $(this);
				$slick_carousel.slick({
					arrows: $slick_carousel.data("arrows"),
					dots: $slick_carousel.data("dots"),
					infinite: $slick_carousel.data("infinite"),
					centerMode: $slick_carousel.data("center-mode"),
					vertical: $slick_carousel.data("vertical"),
					fade: $slick_carousel.data("fade"),
					cssEase: $slick_carousel.data("css-ease"),
					autoplay: $slick_carousel.data("autoplay"),
					verticalSwiping: $slick_carousel.data("vertical-swiping"),
					autoplaySpeed: $slick_carousel.data("autoplay-speed"),
					speed: $slick_carousel.data("speed"),
					pauseOnHover: $slick_carousel.data("pause-on-hover"),
					draggable: $slick_carousel.data("draggable"),
					slidesToShow: $slick_carousel.data("slides-to-show"),
					slidesToScroll: $slick_carousel.data("slides-to-scroll"),
					asNavFor: $slick_carousel.data("as-nav-for"),
					focusOnSelect: $slick_carousel.data("focus-on-select"),
					responsive: $slick_carousel.data("responsive")
				});	
			});

	}

  carousel_slider() {
			$('.carousel_slider').each( function() {
				var $carousel = $(this);
				$carousel.owlCarousel({
					dots : $carousel.data("dots"),
					loop : $carousel.data("loop"),
					items: $carousel.data("items"),
					margin: $carousel.data("margin"),
					mouseDrag: $carousel.data("mouse-drag"),
					touchDrag: $carousel.data("touch-drag"),
					autoHeight: $carousel.data("autoheight"),
					center: $carousel.data("center"),
					nav: $carousel.data("nav"),
					rewind: $carousel.data("rewind"),
					navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
					autoplay : $carousel.data("autoplay"),
					animateIn : $carousel.data("animate-in"),
					animateOut: $carousel.data("animate-out"),
					autoplayTimeout : $carousel.data("autoplay-timeout"),
					smartSpeed: $carousel.data("smart-speed"),
					responsive: $carousel.data("responsive")
				});	
			});
	}

  setPhoto(url){
    this.selectedPhoto = url;
  }

  addToCart(){
	let obj = {
		title:this.product.title,
		price:this.product.price,
		stock:this.product.stock,
		minStock:this.product.minStock,
		qnt:this.qnt,
		imageUrl:this.product.imageUrl=='no-image'?'../../../assets/images/gm-logo.png':this.product.imageUrl,
		id:this.product.id
	}
	this.cartService.addProduct(obj);
	this.qnt = 1;
	Swal.fire("Producto agregado al carrito");
  }

  quickAdd(p){
	let obj = {
		title:p.title,
		price:p.price,
		stock:p.stock,
		minStock:p.minStock,
		qnt:1,
		imageUrl:p.imageUrl=='no-image'?'../../../assets/images/gm-logo.png':p.imageUrl,
		id:p.id
	}
	this.cartService.addProduct(obj);
	Swal.fire("Producto agregado al carrito");
  }

  clusterSelect(e){
	  if (e.target.value!=this.product.id){
		  this.product = undefined;
		  this.router.navigate(['/product/'+e.target.value]);
	  }
  }

}
