<div style="min-height: 100vh;" class="custom-container mt-5 mb-5">
    <div class="col-lg-7 pt-5 offset-lg-3">
        <h2 class="mt-5 mb-5">Fletes</h2>
        <div *ngFor="let value of shippingValues; index as i">
            <h4>Rango {{i+1}} <small><a href="javascript:void(0)" (click)="removeShipping(i)"><i class="ti-close"></i></a></small> </h4>
            <div class="row">
                <div class="form-group col-md-4">
                    <input name="title" [(ngModel)]="shippingValues[i].min" class="form-control" placeholder="Valor inferior (KM)" type="number">
                    <label for="title"><small>Valor inferior (KM)</small></label>
                </div>
                <div class="form-group col-md-4">
                    <input name="description" [(ngModel)]="shippingValues[i].max" class="form-control" placeholder="Valor superior (KM)" type="number">
                    <label for="description"><small>Valor superior (KM)</small></label>
                </div>
                <div class="form-group col-md-4">
                    <input name="id" [(ngModel)]="shippingValues[i].price" class="form-control" placeholder="Precio" type="number">
                    <label for="id"><small>Precio</small></label>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <button type="button" class="btn btn-dark btn-radius" (click)="addShipping()" name="submit" ><i class="ti-plus"></i>Agregar ítem</button>
        </div>
        <div class="col-12" style="text-align:right">
            <a href="javascript:void(0)" class="btn btn-fill-out checkout mb-5" style="margin-right: 0px;" (click)="update()">Actualizar</a>
        </div>
    </div>
</div>