<div class="main_content">
    <div class="section ">
        <div class="container col-lg-7 pt-5 offset-lg-3">
            <div class="row justify-content-center">
                <div class="col-11">
                    <div class="table-responsive shop_cart_table">
                        <table style="table-layout: fixed;" class="table">
                            <thead>
                                <tr>
                                    <th class="product-thumbnail">&nbsp;</th>
                                    <th class="product-name">Producto</th>
                                    <th class="product-price">Precio</th>
                                    <th class="product-quantity">Cantidad</th>
                                    <th class="product-subtotal">Total</th>
                                    <th class="product-remove">Eliminar</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of items; index as i">
                                    <td class="product-thumbnail"><a href="javascript:void(0)"><img [src]="item.imageUrl" alt="p"></a></td>
                                    <td class="product-name" data-title="Producto"><a href="javascript:void(0)">{{item.title}}</a></td>
                                    <td class="product-price" data-title="Precio">${{item.price | number: '0.2'}}</td>
                                    <td class="product-quantity" data-title="Cantidad">
                                        <div class="quantity">
                                            <input type="number" (change)="updateCart(item,i)" [(ngModel)]="item.qnt" class="qty" min="1" [max]="item.stock-item.minStock">
                                        </div>                                        
                                    </td>
                                    <td class="product-subtotal" data-title="Total">${{item.price*item.qnt | number: '0.2'}}</td>
                                    <td class="product-remove" data-title="Eliminar"><a href="javascript:void(0)" (click)="remove(i)"><i class="ti-close"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                        <h3 class="text-center mt-5" *ngIf="items.length==0">No tienes productos en tu carrito</h3>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-11">
                    <div class="medium_divider"></div>
                    <div class="divider center_icon"><i class="ti-shopping-cart-full"></i></div>
                    <div class="medium_divider"></div>
                </div>
            </div>
            <div *ngIf="config&&config.activeCheckout" class="row justify-content-center">
                <div class="col-11">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="heading_s1 mb-3">
                                <h6>Calcular envío</h6>
                            </div>
                                <div class="form-row">
                                    <div class="form-group col-12">
                                        <input [disabled]="shipping!='true'" [(ngModel)]="selector" ngx-google-places-autocomplete autocomplete="off" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" placeholder="Ingrese dirección" class="form-control">
                                    </div>
                                </div>
                                <div class="shipping mb-3">
                                    <div class="row">
                                        <div class="custome-radio col-md-6">
                                            <input class="form-check-input" [(ngModel)]="shipping" required="" type="radio" name="shipping" id="shippingRadio1" value="true" checked="">
                                            <label class="form-check-label" for="shippingRadio1">Envío</label>
                                        </div>
                                        <div class="custome-radio col-md-6">
                                            <input class="form-check-input" (click)="clearShipping()" [(ngModel)]="shipping" type="radio" name="shipping" id="shippingRadio2" value="false">
                                            <label class="form-check-label" for="shippingRadio2">Retiro en local</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="user" class="different_address">
                                    <div class="form-group">
                                        <input type="text" [(ngModel)]="user.userName" disabled required class="form-control" name="fname" placeholder="Nombre y apellido">
                                    </div>
                                    <div class="form-group">
                                        <input disabled type="text" [(ngModel)]="user.email" required class="form-control" name="lname" placeholder="Email">
                                    </div>
                                    <div class="form-group">
                                        <input disabled class="form-control" [(ngModel)]="user.phone" required type="text" name="cname" placeholder="Teléfono">
                                    </div>
                                    <div class="form-group">
                                        <input disabled class="form-control" [(ngModel)]="address" required type="text" name="cname" placeholder="Dirección">
                                    </div>
                                </div>
                                <div class="heading_s1">
                                    <h6>Información adicional</h6>
                                </div>
                                <div class="form-group mb-0">
                                    <textarea [(ngModel)]="additionalInfo" rows="5" class="form-control" placeholder="Agregar información..."></textarea>
                                </div>
                        </div>
                        <div class="col-md-6">
                            <div class="border p-3 p-md-4">
                                <div class="heading_s1 mb-3">
                                    <h6>Totales</h6>
                                </div>
                                <div class="table-responsive">
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class="cart_total_label">Subtotal</td>
                                                <td class="cart_total_amount">${{total | number: '0.2'}}</td>
                                            </tr>
                                            <tr>
                                                <td class="cart_total_label">Envío</td>
                                                <td *ngIf="shippingPrice>0" class="cart_total_amount">${{shippingPrice}}</td>
                                                <td *ngIf="shippingPrice==0" class="cart_total_amount">No disponible</td>
                                            </tr>
                                            <tr>
                                                <td class="cart_total_label">Descuento</td>
                                                <td *ngIf="pMethod=='bank'" class="cart_total_amount">${{(total + shippingPrice)*0.1 | number: '0.0-2'}}</td>
                                                <td *ngIf="pMethod!='bank'" class="cart_total_amount">$0</td>
                                            </tr>
                                            <tr>
                                                <td class="cart_total_label">Total</td>
                                                <td *ngIf="pMethod!='bank'&&pMethod!='card'" class="cart_total_amount"><strong>${{total + shippingPrice | number: '0.2'}}</strong></td>
                                                <td *ngIf="pMethod=='bank'" class="cart_total_amount"><strong>${{(total + shippingPrice)*0.9 | number: '0.0-2'}}</strong></td>
                                                <td *ngIf="pMethod=='card'" class="cart_total_amount"><strong>${{(total + shippingPrice)*selectedCard.factor | number: '0.0-2'}}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div *ngIf="user" class="payment_method">
                                    <div class="heading_s1">
                                        <h6>Métodos de pago</h6>
                                    </div>
                                    <div class="payment_option">
                                        <div class="custome-radio">
                                            <input class="form-check-input" [(ngModel)]="pMethod" required="" type="radio" name="payment_option" id="exampleRadios3" value="bank" checked="">
                                            <label class="form-check-label" for="exampleRadios3">Transferencia bancaria -10% descuento</label>
                                            <p data-method="bank" class="payment-text" >Realizá tu pago a través de una transferencia bancaria con los siguientes datos. Una vez realizada, envianos el comprobante por WhatsApp.</p>
                                            <p data-method="bank" class="payment-text" ><b class="text-dark"><small>GM Sanitarios SRL</small></b></p>
                                            <p data-method="bank" class="payment-text" ><b class="text-dark"><small>Alias: gmsanitarios</small></b></p>
                                            <p data-method="bank" class="payment-text" ><b class="text-dark"><small>Banco Francés</small></b></p>
                                            <p data-method="bank" class="payment-text" ><b class="text-dark"><small>Sucursal 235</small></b></p>
                                            <p data-method="bank" class="payment-text" ><b class="text-dark"><small>Cuenta Corriente 4712/8</small></b></p>
                                            <p data-method="bank" class="payment-text" ><b class="text-dark"><small>CBU 0170235620000000471286</small></b></p>
                                            <p data-method="bank" class="payment-text" ><b class="text-dark"><small>CUIT 30-69261774-2</small></b></p>
                                        </div>
                                        <div class="custome-radio">
                                            <input class="form-check-input" [(ngModel)]="pMethod" [ngModelChange]="change($event)" type="radio" name="payment_option" id="exampleRadios4" value="card">
                                            <label class="form-check-label" for="exampleRadios4">Tarjetas de crédito o débito</label>
                                            <p data-method="card" class="payment-text" >A través de link de pago por WhatsApp</p>
                                            <ul data-method="card" style="margin-top: 20px;" id="cards-container" class="list-group">
                                                <li class="list-group-item active cardHeader">¡Aprovechá nuestras promociones bancarias!</li>
                                                <li style="padding: 0;" *ngFor="let card of cards; index as i" class="list-group-item">
                                                    <div class="form-check pt-3 pb-3">
                                                        <input class="form-check-input" [(ngModel)]="selectedCard" [ngModelChange]="changeSelectedCard($event)" type="radio" name="flexRadioDefault" [id]="'card'+i" [value]="card">
                                                        <label class="form-check-label" [for]="'card'+i">
                                                        </label>
                                                        <div style="margin-left: 10px ;margin-right: 10px; display: inline;">
                                                            <img [src]="card.imageUrl" width="40" alt=""> 
                                                        </div>
                                                        <div style="display: inline-block; vertical-align: middle;">
                                                            <small><b> {{card.qnt}} {{card.description}} ${{((total+shippingPrice)*card.factor/card.qnt) | number:'0.0-2'}}</b></small>
                                                            <small style="font-weight: 300; color: '#5c5e64'; display: block; text-align: center;">{{card.postDescription}}</small>
                                                        </div>
                                                    <!-- <img [src]="card.imageUrl" width="40" alt=""><small><b> {{card.qnt}} {{card.description}} ${{((total+shippingPrice)*card.factor/card.qnt) | number:'0.0-2'}} {{card.postDescription}}</b></small> -->
                                                      </div>
                                                </li>
                                                <li class="list-group-item active cardHeader">Seleccioná tu tarjeta</li>
                                                <li style="padding: 0;" class="list-group-item">
                                                    <div style="padding-left: 1.25rem;" class="row">
                                                        <div class="col-6 form-check pt-3 pb-3">
                                                            <input class="form-check-input" [(ngModel)]="cardType" type="radio" name="visa" id="visa" value="visa">
                                                            <label class="form-check-label" for="visa">
                                                            </label>
                                                            <div style="margin-left: 10px ;margin-right: 10px; display: inline;">
                                                                <img src="../../../assets/images/visa-logo.png" width="40" alt=""> 
                                                            </div>
                                                            <div style="display: inline-block; vertical-align: middle;">
                                                                <small><b> VISA</b></small>
                                                            </div>
                                                        </div>
    
                                                        <div class="col-6 form-check pt-3 pb-3">
                                                            <input class="form-check-input" [(ngModel)]="cardType" type="radio" name="master" id="master" value="master">
                                                            <label class="form-check-label" for="master">
                                                            </label>
                                                            <div style="margin-left: 10px ;margin-right: 10px; display: inline;">
                                                                <img src="../../../assets/images/mastercard-logo.png" width="40" alt=""> 
                                                            </div>
                                                            <div style="display: inline-block; vertical-align: middle;">
                                                                <small><b> MASTERCARD</b></small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="custome-radio">
                                            <input class="form-check-input" [(ngModel)]="pMethod" type="radio" name="payment_option" id="exampleRadios5" value="mp">
                                            <label class="form-check-label" for="exampleRadios5">Mercado Pago</label>
                                            <p data-method="mp" class="payment-text" >Realizá tu pago a través de Mercado Pago</p>
                                            <div #mpcontainer class="mp-container"></div>
                                        </div>
                                        <div *ngIf="user.cte" class="custome-radio">
                                            <input class="form-check-input" [(ngModel)]="pMethod" type="radio" name="payment_option" id="exampleRadios6" value="cte">
                                            <label class="form-check-label" for="exampleRadios6">Cuenta corriente</label>
                                            <p data-method="cte" class="payment-text" >Tu pedido será cargado en tu cta. corriente</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <a href="javascript:void(0)" (click)="handlePayment()" class="btn btn-fill-out">Realizar pago</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
