import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  public orders = [];
  public selectedOrder;
  public userForm = this.fb.group({
    userName:['', Validators.required],
    email:['', Validators.email],
    phone:[''],
    _id:['']
  })
  public newPassword="";

  constructor(
    private fb:FormBuilder,
    private userService:UserService,
    private orderService:OrderService,
    private router:Router
  ) { 
    this.userService.getUser().subscribe(
      resp=>{
        this.userForm.get('userName').setValue(resp.userName);
        this.userForm.get('phone').setValue(resp.phone);
        this.userForm.get('email').setValue(resp.email);
        this.userForm.get('_id').setValue(resp._id);
      },
      err=>console.log(err)
    )
  }

  ngOnInit(): void {
    this.userService.getUser().subscribe(
      resp=>{
        this.orderService.getUserOrders({_id:resp._id}).subscribe(
          orders=>{
            this.orders = orders;
          },
          err=>console.log(err)
        )
      },
      err=>console.log(err)
    )
  }

  updatePassword(){
    let obj = {
      _id:this.userForm.value._id,
      password:this.newPassword
    }
    this.userService.updateUser(obj).subscribe(
      resp=>{
        Swal.fire({
          icon:"success",
          title:"Contraseña actualizada"
        })
      },
      err=>console.log(err)
    )
  }

  update(){
    let obj = {
      _id:this.userForm.value._id,
      userName:this.userForm.value.userName,
      phone:this.userForm.value.phone,
      email:this.userForm.value.email,
    }
    this.userService.updateUser(obj).subscribe(
      resp=>{
        Swal.fire({
          icon:"success",
          title:"Usuario actualizado"
        })
      },
      err=>console.log(err)
    )
  }

  logout(){
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');
    location.reload();
  }

}
