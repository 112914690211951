import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

declare function initLoad();
declare var $;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: [
  ]
})
export class MainComponent implements OnInit {

  mainBannerProducts = []
  sideMainBanner = []
  singlePromo = {
    title:"",
    id:""
  }
  newProducts = [];
  featuredProducts = []
  lastMainBanner = [];
  logos = []
  public loaded;

  public raffleForm = this.fb.group({
	  name:['', Validators.required],
	  email:['', Validators.email],
	  dni:['', Validators.required],
	  phone:['', Validators.required],
	  hash:['', Validators.required],
	  eventName:['', Validators.required]
  })

  constructor(
    private productService:ProductService,
	private cartService:CartService,
	private router:Router,
    @Inject(PLATFORM_ID) private platformId,
	private route: ActivatedRoute,
	private fb: FormBuilder,
	private userService: UserService
  ) { 

	
    if (isPlatformBrowser(this.platformId)){
		this.productService.getBannerConfig().subscribe(
		  resp=>{
			let newIds = [];
			let featuredIds = [];
			this.logos = resp[0].data.logos;
			this.mainBannerProducts = resp[0].data.mainBannerProducts;
			this.sideMainBanner = resp[0].data.sideMainBanner;
			this.singlePromo = resp[0].data.singlePromo;
			this.newProducts = resp[0].data.newProducts;
			this.featuredProducts = resp[0].data.featuredProducts;
			this.lastMainBanner = resp[0].data.lastMainBanner;
			this.newProducts.map(
				p => {
					newIds.push(p.id);
				}
			)
			this.featuredProducts.map(
				p => {
					featuredIds.push(p.id);
				}
			)
			this.productService.getProductsByIds({ids:newIds}).subscribe(
				resp=>{
					let array = [];
					resp.map(
						pe=>{
							this.newProducts.map(
								pi=>{
									if (pe.id==pi.id) {
										pi.price = pe.price;
										pi.minStock = pe.minStock;
										array.push(pi);
									}
								}
							)	
						}
					)
					this.newProducts = array;
					this.productService.getProductsByIds({ids:featuredIds}).subscribe(
						resp=>{
							array = [];
							resp.map(
								pe=>{
									this.featuredProducts.map(
										pi=>{
											if (pe.id==pi.id) {
												pi.price = pe.price;
												pi.minStock = pe.minStock;
												array.push(pi);
											}
										}
									)	
								}
							)
							this.featuredProducts = array;
							setTimeout(()=>{
								this.carousel_slider();
							}, 200)
						}
					)
				}
			)
		  },
		  err=>console.log(err)
		)

	}
  }

  ngOnInit(): void {
	let action = this.route.snapshot.queryParamMap.get('action');
	if (action == 'register'){
		$('#qrModal').modal('show');
		let eventName = this.route.snapshot.queryParamMap.get('id');
		let hash = this.route.snapshot.queryParamMap.get('hash');
		this.raffleForm.get('eventName').setValue(eventName);
		this.raffleForm.get('hash').setValue(hash);
	}

  }

  carousel_slider() {
		setTimeout(()=>{
			$('.carousel_slider').each( function() {
				var $carousel = $(this);
				$carousel.owlCarousel({
					dots : $carousel.data("dots"),
					loop : $carousel.data("loop"),
					items: $carousel.data("items"),
					margin: $carousel.data("margin"),
					mouseDrag: $carousel.data("mouse-drag"),
					touchDrag: $carousel.data("touch-drag"),
					autoHeight: $carousel.data("autoheight"),
					center: $carousel.data("center"),
					nav: $carousel.data("nav"),
					rewind: $carousel.data("rewind"),
					navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
					autoplay : $carousel.data("autoplay"),
					animateIn : $carousel.data("animate-in"),
					animateOut: $carousel.data("animate-out"),
					autoplayTimeout : $carousel.data("autoplay-timeout"),
					smartSpeed: $carousel.data("smart-speed"),
					responsive: $carousel.data("responsive")
				});	
			});
		},100);
	}
	
	slick_slider() {
		$('.slick_slider').each( function() {
			var $slick_carousel = $(this);
			$slick_carousel.slick({
				arrows: $slick_carousel.data("arrows"),
				dots: $slick_carousel.data("dots"),
				infinite: $slick_carousel.data("infinite"),
				centerMode: $slick_carousel.data("center-mode"),
				vertical: $slick_carousel.data("vertical"),
				fade: $slick_carousel.data("fade"),
				cssEase: $slick_carousel.data("css-ease"),
				autoplay: $slick_carousel.data("autoplay"),
				verticalSwiping: $slick_carousel.data("vertical-swiping"),
				autoplaySpeed: $slick_carousel.data("autoplay-speed"),
				speed: $slick_carousel.data("speed"),
				pauseOnHover: $slick_carousel.data("pause-on-hover"),
				draggable: $slick_carousel.data("draggable"),
				slidesToShow: $slick_carousel.data("slides-to-show"),
				slidesToScroll: $slick_carousel.data("slides-to-scroll"),
				asNavFor: $slick_carousel.data("as-nav-for"),
				focusOnSelect: $slick_carousel.data("focus-on-select"),
				responsive: $slick_carousel.data("responsive")
			});	
		});
	}

	addToCart(p){
		let obj = {
			_id:p._id,
			title:p.title,
			imageUrl:p.image,
			qnt:1,
			price:p.price
		}
		
		this.cartService.addProduct(obj);
		Swal.fire({
			title:"Producto agregado al carrito",
			icon:"success"
		})
	}

	handleNavigate(p){
		if (p.category){
			this.router.navigate(['viewProducts'],{queryParams:{action:"category", value:p.id}});
		}
		if(!p.category){
			this.router.navigate([`product/${p.id}`]);
		}
	}

	registerRaffle(){
		$('#qrModal').modal('hide');
		this.userService.registerRaffle(this.raffleForm.value).subscribe(
			resp=>{
				Swal.fire({
					icon:'success',
					text:'¡Gracias por registrarte!',
					timer: 5000
				}).then(
					resp=>{
						this.router.navigate([''])
					}
				)
			},
			err=>{
				Swal.fire({
					icon:'error',
					text:'Código ya utilizado',
					showConfirmButton: false,
					timer: 2000
				}).then(
					resp=>{
						this.router.navigate([''])

					}
				)
			}
		)
	}




}
