import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {

  public userFilter = "";
  public users = [];
  public p = 1;
  public isEditing = false;
  public selectedUser;
  public newPassword;

  constructor(
    private userService:UserService,
    private orderService:OrderService
  ) { 
    this.userService.getUsers().subscribe(
      resp=>{
        this.users = resp;
      },
      err=>console.log(err)
    )
  }

  ngOnInit(): void {
  }

  deleteUser(u){
    Swal.fire({
      title:"¿Borrar usuario?",
      icon:"question",
      showCancelButton:true,
      confirmButtonText:"Si",
      cancelButtonText:"No",
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.userService.deleteUser(u).subscribe(
            resp=>{
              Swal.fire({
                title:"Usuario Borrado",
                icon:"success"
              }).then(
                resp=>window.location.reload()
              )
            },
            err=>console.log(err)
          )
        }
      }
    )
  }

  editUser(u){
    this.selectedUser = u;
    this.isEditing = true;
  }

  viewOrders(u){
    this.orderService.getUserOrders(u).subscribe(
      resp=>{
        console.log(resp)
      },
      err=>console.log(err)
    )
  }

  update(){
    this.userService.updateUser(this.selectedUser).subscribe(
      resp=>{
        Swal.fire({
          title:"Usuario Actualizado",
          icon:"success"
        })
      },
      err=>console.log(err)
    )
  }

  updatePassword(){
    this.userService.updateUser({_id:this.selectedUser._id, password:this.newPassword}).subscribe(
      resp=>{
        Swal.fire('Contraseña actualizada')
        this.newPassword = '';
      },
      err=>console.log(err)
    )

  }

}
