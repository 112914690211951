import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { UploadService } from 'src/app/services/upload.service';
import { Editor, toDoc, toHTML } from 'ngx-editor';
import Swal from 'sweetalert2';

declare var $:any;

@Component({
  selector: 'app-edit-products',
  templateUrl: './edit-products.component.html',
  styleUrls: ['./edit-products.component.css']
})
export class EditProductsComponent implements OnInit {

  @ViewChild('f1') f1:ElementRef;
  @ViewChild('f2') f2:ElementRef;
  @ViewChild('f3') f3:ElementRef;

  public p = 1;
  public products = [];
  public categories = [];
  public isEditing = false;
  public isUploading = false;
  public selectedProduct;
  public tag="";
  public tagValue="";
  public related="";
  public category="";
  public tempImg;
  public file;
  public titleFilter="";
  public idFilter="";
  public tradeMarkFilter="";
  public html;
  public editor:Editor;
  public selectedIndex = 0;

  constructor(
    private productService:ProductService,
    private uploadService:UploadService,
    @Inject(PLATFORM_ID) private platformId

  ) { }

  ngOnInit(): void {
    this.editor = new Editor();
    this.productService.getCategories().subscribe(
      resp=>{
        this.categories = resp.data.categories;
      },
      err=>console.log(err)
    )
    this.productService.getProducts().subscribe(
      resp=>{
        this.products = resp;
      },
      err=>console.log(err)
    )
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  deleteProduct(p){
    Swal.fire({
      title:"¿Eliminar producto?",
      showCancelButton:true,
      confirmButtonText:"Eliminar",
      cancelButtonText:"Cancelar"
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.productService.deleteProduct(p).subscribe(
            resp=>{
              window.location.reload()
            },
            err=>console.log(err)
          )
        }
      }
    )
  }

  edit(p, i){
    if (isPlatformBrowser(this.platformId)){
      scrollTo(0,0);
      this.selectedProduct = p;
      this.html = toDoc(this.selectedProduct.info);
      this.isEditing = true;
      this.selectedIndex = i + (this.p-1)*25;
    }
  }

  next(){
    if (isPlatformBrowser(this.platformId)){
      scrollTo(0,0);
      this.f1.nativeElement.value = "";
      this.f2.nativeElement.value = "";
      this.f3.nativeElement.value = "";
      if(this.selectedIndex<this.products.length){
        this.selectedIndex++;
        this.selectedProduct = this.products[this.selectedIndex];
        this.html = toDoc(this.selectedProduct.info);
      }
    }
  }

  previous(){
    if (isPlatformBrowser(this.platformId)){
      scrollTo(0,0);
      this.f1.nativeElement.value = "";
      this.f2.nativeElement.value = "";
      this.f3.nativeElement.value = "";
      if(this.selectedIndex>0){
        this.selectedIndex--;
        this.selectedProduct = this.products[this.selectedIndex];
        this.html = toDoc(this.selectedProduct.info);  
      }
    }
  }

  addTag(){
    let obj = {
      tag:this.tag,
      value:this.tagValue
    }
    this.selectedProduct.tags.push(obj);
    this.tag = "";
    this.tagValue = "";
  }

  addCategory(){
    this.selectedProduct.categories.push(this.category);
    this.category = "";
  }

  addRelated(){
    this.selectedProduct.related.push(this.related);
    this.related = "";
  }

  deleteTag(index){
    this.selectedProduct.tags.splice(index,1);
  }

  deleteCategory(index){
    this.selectedProduct.categories.splice(index,1);
  }

  deleteRelated(index){
    this.selectedProduct.related.splice(index,1);
  }

  getFile(e,n){
    Swal.fire({
      title:"¿Actualizar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.isUploading = true;
          var reader = new FileReader();
          reader.onloadend = ()=>{
            this.tempImg = reader.result;
          }
          reader.readAsDataURL(e.target.files[0]);
          this.file=e.target.files[0];
          this.uploadService.uploadImage(this.file).subscribe(
            resp=>{
              let obj;
              if(n==1){
                this.selectedProduct.imageUrl = resp.secure_url;
                obj = {
                  _id:this.selectedProduct._id,
                  imageUrl:resp.secure_url
                };
              }
              if(n==2){
                this.selectedProduct.imageUrl2 = resp.secure_url;
                obj = {
                  _id:this.selectedProduct._id,
                  imageUrl2:resp.secure_url
                };
              }
              if(n==3){
                this.selectedProduct.imageUrl3 = resp.secure_url;
                obj = {
                  _id:this.selectedProduct._id,
                  imageUrl3:resp.secure_url
                };
              }
              this.productService.updateProduct(obj).subscribe(
                resp=>{
                  this.isUploading = false;
                  Swal.fire("Imagen actualizada");
                },
                err=>console.log(err)
              )
          },
            err=>console.log(err)
          )
        }
      }
    )
  }

  update(){
    this.selectedProduct.info = toHTML(this.html);
    this.productService.updateProduct(this.selectedProduct).subscribe(
      resp=>{
        Swal.fire("Producto Actualizado");
        if (isPlatformBrowser(this.platformId)){
          scrollTo(0,0);
        }

      },
      err=>console.log(err)
    )
  }

  resetPageNumber(){
    this.p=1;
  }

}
