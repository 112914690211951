import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    private http:HttpClient
  ) { }

  uploadImage(file):Observable<any>{
    var formData = new FormData();
    formData.append("file", file);
    return this.http.post(`${environment.apiBaseUrl}/upload/image`,formData);
  }

}
