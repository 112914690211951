import { Component, OnInit, Renderer2, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import Swal from 'sweetalert2';
declare var $:any;
declare function initLoad();
declare var MercadoPago:any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  @ViewChild("placesRef") placesRef;
  @ViewChild("mpcontainer") mpcontainer;
  public user;
  public additionalInfo = "";
  public pMethod = "bank";
  public shippingPrice = 0;
  public shippingValues = [];
  public gmLoca = {
    lat:-38.05260547414345,
    lng:-57.56078510771117
  }
  public config;

  public address="";
  public shipping = "false";
  public selector;
  public cards = [];
  public selectedCard;
  public cardType = 'visa';
  
  constructor(
    private cartService:CartService,
    private userService:UserService,
    private orderService:OrderService,
    private router:Router,
    private route:ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId
  ) { 
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      resp=>{
        if (resp.action=='success'){
          this.cartService.products = [];
        }
      },
      err=>console.log(err)
    )
    this.orderService.getCreditCards().subscribe(
      resp=>{
        this.cards = resp;
        this.selectedCard = resp[0]
      },
      err=>console.log(err)
    )
    this.userService.getUser().subscribe(
      resp=>{
        this.user = resp;
        setTimeout(()=>{
          $(document).ready(function () {
            $('[name="payment_option"]').on('change', function() {
              var $value = $(this).attr('value');
              $('.payment-text').slideUp();
              $('[data-method="'+$value+'"]').slideDown();
            });
          });
        },500)
      },
      err=>console.log(err)
    )
    this.orderService.getShippingValues().subscribe(
      resp=>{
        if (resp) this.shippingValues = resp.data;
      },
      err=>console.log(err)
    )

    this.orderService.getConfig().subscribe(
      resp=>{
        this.config = resp;
      },
      err=>console.log(err)
    )
  }

  get items(){
    return this.cartService.products;
  }

  get total(){
    return this.cartService.total;
  }

  remove(i){
    this.cartService.deleteProduct(i);
  }

  change(e){
    if (this.pMethod=='card'){
      $('#cards-container').css("display", "block");
    }else $('#cards-container').css("display", "none");
  }

  handlePayment(){
    if(this.items.length>0){
      if(this.shippingPrice==0&&this.shipping=='true'){
        Swal.fire({
          icon:"info",
          title:"Dirección de envío no válida",
          text:"La dirección seleccionada no se encuentra disponible. Elegí otra o seleccioná retiro en local."
        })
      }else{
        let obj = {
          userId:this.user._id,
          userEmail:this.user.email,
          userPhone:this.user.phone,
          userName:this.user.userName,
          total:this.total,
          additionalInfo:this.additionalInfo,
          shippingPrice:this.shippingPrice,
          items:this.items,
          address:this.address?this.address:'Retiro en local',
          shipping:this.shippingPrice>0?true:false,
          paymentMethod:this.pMethod
        }
        if (this.pMethod == "bank"){
          obj.total = obj.total*0.9;
          obj.shippingPrice = obj.shippingPrice*0.9;
          this.orderService.createOrder(obj).subscribe(
            resp=>{
              Swal.fire({
                title:"Pedido realizado",
                text:"Recordá enviarnos el comprobante de la transferencia bancaria para finalizar la compra"
              }).then(
                resp=>{
                  this.cartService.products = [];
                  this.cartService.total = 0;
                  this.router.navigate(['main']);
                }
              )
            },
            err=>console.log(err)
          )
        }
        if (this.pMethod == "card"){
          let total = ((this.total+this.shippingPrice)*this.selectedCard.factor/this.selectedCard.qnt).toFixed(2);
          obj.paymentMethod = `${this.cardType} ${this.selectedCard.qnt} cuotas ${this.selectedCard.description} $${total} ${this.selectedCard.postDescription}`;
          obj.total = obj.total*this.selectedCard.factor;
          obj.shippingPrice = obj.shippingPrice*this.selectedCard.factor;
          this.orderService.createOrder(obj).subscribe(
            resp=>{
              Swal.fire({
                title:"Pedido realizado",
                text:"Te enviaremos el link de pago por WhatsApp"
              }).then(
                resp=>{
                  this.cartService.products = [];
                  this.cartService.total = 0;
                  this.router.navigate(['main']);
                }
              )
            },
            err=>console.log(err)
          )
        }
        if (this.pMethod == "mp"){
          let data = [];
          this.items.map(
            item=>{
              let aux = {
                id:item.id,
                title:item.title,
                description:item.title,
                quantity:item.qnt,
                unit_price:item.price
              }
              data.push(aux)
            }
          )
          if (this.shippingPrice>0){
            let aux = {
              id:"flete",
              title:"flete",
              description:"flete",
              quantity:1,
              unit_price:this.shippingPrice
            }
            data.push(aux);
          }
          this.orderService.createMpOrder(data, obj).subscribe(
            resp=>{
              const mp = new MercadoPago('TEST-1a766c8e-4a5c-4d32-accf-148b056a2bc2', {
                locale: 'es-AR'
              });
              let checkout = mp.checkout({
                  preference: {
                      id: resp.id
                  },
                  theme: {
                    elementsColor: '#5c5e64'
                },
                  autoOpen:true,
              });
            },
            err=>console.log(err)
          )
        }
        if (this.pMethod == "cte"){
          this.orderService.createOrder(obj).subscribe(
            resp=>{
              Swal.fire({
                title:"Pedido realizado",
              }).then(
                resp=>{
                  this.cartService.products = [];
                  this.cartService.total = 0;
                  this.router.navigate(['main']);
                }
              )
            },
            err=>console.log(err)
          )
        }
      }
    }
  }

  handleAddressChange(address){
    if (isPlatformBrowser(this.platformId)){
      this.address = address.formatted_address;
      let dist = this.getDistanceFromLatLonInKm(this.gmLoca.lat, this.gmLoca.lng, address.geometry.location.lat(), address.geometry.location.lng());
      this.shippingValues.map(
        value=>{
          if(value.min<=dist&&value.max>=dist) this.shippingPrice = value.price
        }
      )
    }
  }

  getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
    if (isPlatformBrowser(this.platformId)){
      var R = 6371; // Radius of the earth in km
      var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
      var dLon = this.deg2rad(lon2-lon1); 
      var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      var d = R * c; // Distance in km
      var cat = d/Math.sqrt(2);
      return Math.round(2*cat-1);
    }
  }

  deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  clearShipping(){
    this.address = "";
    this.selector = "";
    this.shippingPrice = 0;
  }

  changeSelectedCard(c){
    // console.log(this.selectedCard)
    // console.log(this.cardType)
  }

  updateCart(item){
    this.cartService.setNewQuantity(item);
  }

}
