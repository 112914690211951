<div style="min-height: 100vh;" class="custom-container">
    <div class="col-lg-7 pt-5 offset-lg-3 mt-5">
        <h2 class="mt-5 mb-5">Pedidos/Configuraciones del sitio</h2>
        <div class="col-12">
            <div  *ngIf="config" class="row">
                <div class="col-md-6">
                    <input required="" [(ngModel)]="popUpText" class="form-control mb-5" placeholder="Texto del popup" type="text">
                </div>
                <div class="col-md-6 ">
                    <div class="row">
                        <button (click)="updatePopupText()" class="btn btn-rounded btn-sm btn-info">Actualizar text popup</button>
                        <button *ngIf="config.activePopup" (click)="changePopupState()" class="btn btn-rounded btn-sm btn-danger">Desactivar PopUp</button>
                        <button *ngIf="!config.activePopup" (click)="changePopupState()" class="btn btn-rounded btn-sm btn-success">Activar PopUp</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <input required="" [(ngModel)]="userFilter" class="form-control mb-5" placeholder="Filtrar por nombre de cliente" type="text">
                </div>
                <div class="col-md-4"></div>
                <div *ngIf="config" class="col-md-4 ">
                    <button *ngIf="config.activeCheckout" (click)="changeState()" class="btn btn-rounded btn-danger">Desactivar ventas</button>
                    <button *ngIf="!config.activeCheckout" (click)="changeState()" class="btn btn-rounded btn-success">Activar ventas</button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th >Cliente</th>
                            <th >Teléfono</th>
                            <th >Fecha</th>
                            <th >Items</th>
                            <th >Total</th>
                            <th >Envío</th>
                            <th >Acción</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of orders | user:userFilter | paginate: { itemsPerPage: 25, currentPage: p }; index as i">
                            <td >{{order.userName}}</td>
                            <td >{{order.userPhone}}</td>
                            <td >{{order.date | date:'shortDate'}}</td>
                            <td ><a href="javascript:void(0)" (click)="showItems(order)" >Ver</a></td>
                            <td >${{order.total+order.shippingPrice | number:'0.2'}}</td>
                            <td *ngIf="order.shipping" class="product-stock-status" data-title="Stock Status"><span class="badge badge-pill badge-success">Si</span></td>
                            <td *ngIf="!order.shipping" class="product-stock-status" data-title="Stock Status"><span class="badge badge-pill badge-danger">No</span></td>
                            <td class="product-remove" data-title="Remove">
                                <a class="delete" (click)="deleteOrder(order)" href="javascript:void(0)">Borrar</a><span> / </span>
                                <a class="delete" (click)="exportCsv(order)" href="javascript:void(0)">CSV</a><span> / </span>
                                <a class="delete" (click)="showOrder(order)" href="javascript:void(0)">Ver pedido</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="row justify-content-center mb-3">
                    <pagination-controls 
                        class="center"
                        (pageChange)="p = $event"
                        nextLabel="Siguiente"
                        previousLabel="Anterior"
                        responsive="true"
                        autoHide="true"
                    >
                    </pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="itemsModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Items</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div *ngIf="selectedOrder" class="modal-body">
            <div class="table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th >ID</th>
                            <th >Título</th>
                            <th >Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let it of selectedOrder.items ; index as i">
                            <td >{{it.id}}</td>
                            <td >{{it.title}}</td>
                            <td >{{it.qnt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
      </div>
    </div>
  </div>

<div class="modal fade bd-example-modal-lg" id="orderModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div *ngIf="selectedOrder" class="modal-content p-5">
        <div class="modal-header mb-2">
            <h4 class="modal-title" id="exampleModalLabel">Pedido</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <h5>Cliente: {{selectedOrder.userName}}</h5>
            <h5>Email: {{selectedOrder.userEmail}}</h5>
            <h5>Telefono: {{selectedOrder.userPhone}}</h5>
            <h5>Fecha: {{selectedOrder.date | date:"shortDate"}}</h5>
            <h5>Total: $ {{selectedOrder.total | number:'0.2'}}</h5>
            <h5>Envío: {{selectedOrder.shipping==true?"Si":"No"}}</h5>
            <h5>Dirección de envío: {{selectedOrder.address}}</h5>
            <h5>Precio de envío: $ {{selectedOrder.shippingPrice}}</h5>
            <h5>Información adicional: {{selectedOrder.additionalInfo}}</h5>
            <h5>Método de pago: {{selectedOrder.paymentMethod}}</h5>
            <div class="table-responsive mt-5">
                <h3>Items</h3>
                <table class="table">
                    <thead>
                        <tr>
                            <th >ID</th>
                            <th >Título</th>
                            <th >Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let it of selectedOrder.items ; index as i">
                            <td >{{it.id}}</td>
                            <td >{{it.title}}</td>
                            <td >{{it.qnt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    </div>
</div>