import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { UploadService } from 'src/app/services/upload.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-banners-config',
  templateUrl: './banners-config.component.html',
  styles: [
  ]
})
export class BannersConfigComponent implements OnInit {
  public bannerConfig;
  public trademarkTitle;
  public trademarkImage;
  public isUploading = false;
  public file;
  constructor(
    private productService:ProductService,
    private uploadService:UploadService
  ) { 
    this.productService.getBannerConfig().subscribe(
      resp=>{
        this.bannerConfig = resp[0];
      },
      err=>console.log(err)
    )
  }

  ngOnInit(): void {

  }

  addTrademark(){
    let obj = {
      title:"",
      image:""
    };
    this.bannerConfig.data.logos.push(obj);
    this.trademarkImage = "";
    this.trademarkTitle = "";
  }

  removeTrademark(index){
    this.bannerConfig.data.logos.splice(index, 1);
  }

  update(){
    this.productService.updateBanner(this.bannerConfig).subscribe(
      resp=>{
        Swal.fire("Configuración actualizada");
      },
      err=>console.log(err)
    )
  }

  addSlide(){
    this.bannerConfig.data.mainBannerProducts.push({
      id:"",
      title:"",
      description:"",
      imageUrl:""
    })
  }

  removeSlide(i){
    this.bannerConfig.data.mainBannerProducts.splice(i,1);
  }

  mainBannerImage(e,i){
    Swal.fire({
      title:"¿Actualizar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.isUploading = true;
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          this.file=e.target.files[0];
          this.uploadService.uploadImage(this.file).subscribe(
            resp=>{
              this.bannerConfig.data.mainBannerProducts[i].image = resp.secure_url;
              this.productService.updateBanner(this.bannerConfig).subscribe(
                resp=>{
                  this.isUploading = false;
                  Swal.fire("Configuración actualizada");
                },
                err=>console.log(err)
              )
          },
            err=>console.log(err)
          )
        }
      }
    )
  }

  sideMainBannerImage(e,i){
    Swal.fire({
      title:"¿Actualizar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.isUploading = true;
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          this.file=e.target.files[0];
          this.uploadService.uploadImage(this.file).subscribe(
            resp=>{
              this.bannerConfig.data.sideMainBanner[i].image = resp.secure_url;
              this.productService.updateBanner(this.bannerConfig).subscribe(
                resp=>{
                  this.isUploading = false;
                  Swal.fire("Configuración actualizada");
                },
                err=>console.log(err)
              )
          },
            err=>console.log(err)
          )
        }
      }
    )
  }

  leftBannerImage(e){
    Swal.fire({
      title:"¿Actualizar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.isUploading = true;
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          this.file=e.target.files[0];
          this.uploadService.uploadImage(this.file).subscribe(
            resp=>{
              this.bannerConfig.data.singlePromo.image = resp.secure_url;
              this.productService.updateBanner(this.bannerConfig).subscribe(
                resp=>{
                  this.isUploading = false;
                  Swal.fire("Configuración actualizada");
                },
                err=>console.log(err)
              )
          },
            err=>console.log(err)
          )
        }
      }
    )
  }


  newProductsImage(e,i,h){
    Swal.fire({
      title:"¿Actualizar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.isUploading = true;
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          this.file=e.target.files[0];
          this.uploadService.uploadImage(this.file).subscribe(
            resp=>{
              if (!h) this.bannerConfig.data.newProducts[i].image = resp.secure_url;
              else this.bannerConfig.data.newProducts[i].imageHover = resp.secure_url;
              this.productService.updateBanner(this.bannerConfig).subscribe(
                resp=>{
                  this.isUploading = false;
                  Swal.fire("Configuración actualizada");
                },
                err=>console.log(err)
              )
          },
            err=>console.log(err)
          )
        }
      }
    )
  }

  removeNewProductImage(i,h){
    Swal.fire({
      title:"Quitar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          if (!h) this.bannerConfig.data.newProducts[i].image = "";
          else this.bannerConfig.data.newProducts[i].imageHover = "";
          this.productService.updateBanner(this.bannerConfig).subscribe(
            resp=>{
              this.isUploading = false;
              Swal.fire("Configuración actualizada");
            },
            err=>console.log(err)
          )
        }
      }
    )
  }

  removeFeaturedProductImage(i,h){
    Swal.fire({
      title:"Quitar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          if (!h) this.bannerConfig.data.featuredProducts[i].image = "";
          else this.bannerConfig.data.featuredProducts[i].imageHover = "";
          this.productService.updateBanner(this.bannerConfig).subscribe(
            resp=>{
              this.isUploading = false;
              Swal.fire("Configuración actualizada");
            },
            err=>console.log(err)
          )
        }
      }
    )
  }

  featuredProductsImage(e,i,h){
    Swal.fire({
      title:"¿Actualizar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.isUploading = true;
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          this.file=e.target.files[0];
          this.uploadService.uploadImage(this.file).subscribe(
            resp=>{
              if (!h) this.bannerConfig.data.featuredProducts[i].image = resp.secure_url;
              else this.bannerConfig.data.featuredProducts[i].imageHover = resp.secure_url;
              this.productService.updateBanner(this.bannerConfig).subscribe(
                resp=>{
                  this.isUploading = false;
                  Swal.fire("Configuración actualizada");
                },
                err=>console.log(err)
              )
          },
            err=>console.log(err)
          )
        }
      }
    )
  }

  lastMainBannerImage(e,i){
    Swal.fire({
      title:"¿Actualizar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.isUploading = true;
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          this.file=e.target.files[0];
          this.uploadService.uploadImage(this.file).subscribe(
            resp=>{
              this.bannerConfig.data.lastMainBanner[i].image = resp.secure_url;
              this.productService.updateBanner(this.bannerConfig).subscribe(
                resp=>{
                  this.isUploading = false;
                  Swal.fire("Configuración actualizada");
                },
                err=>console.log(err)
              )
          },
            err=>console.log(err)
          )
        }
      }
    )
  }

  tImage(e,i){
    Swal.fire({
      title:"¿Actualizar imagen?",
      showCancelButton:true
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.isUploading = true;
          var reader = new FileReader();
          reader.readAsDataURL(e.target.files[0]);
          this.file=e.target.files[0];
          this.uploadService.uploadImage(this.file).subscribe(
            resp=>{
              this.bannerConfig.data.logos[i].image = resp.secure_url;
              this.productService.updateBanner(this.bannerConfig).subscribe(
                resp=>{
                  this.isUploading = false;
                  Swal.fire("Configuración actualizada");
                },
                err=>console.log(err)
              )
          },
            err=>console.log(err)
          )
        }
      }
    )
  }

}
