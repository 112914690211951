import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { CartService } from './services/cart.service';
import { OrderService } from './services/order.service';
import { ProductService } from './services/product.service';
import { UserService } from './services/user.service';
import { environment } from 'src/environments/environment.prod';

declare function initLoad():any

declare var $:any;

declare let gtag: (property: string, value: any, configs: any) => {};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  public menu = [];
  public menuCategories = [];
  public user;
  public searchText = "";
  public restoring = false;
  public restoringEmail = "";
  public userForm = this.fb.group({
    email:['', Validators.email],
    password:['', Validators.required],
    rememberMe:[false],
  })
  public config;

  constructor(
    private productService:ProductService,
    private router:Router,
    private cartService:CartService,
    private userService:UserService,
    private fb:FormBuilder,
    private orderService:OrderService,
    private title:Title,
    @Inject(PLATFORM_ID) private platformId

  ){
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     gtag('config', environment.gaId, {
    //       page_path: event.urlAfterRedirects
    //     });
    //   }
    // });
    this.productService.getCategories().subscribe(
      resp=>{
        let categoriesContainers = resp.data.containers;
        let categories = resp.data.categories;
        categoriesContainers.map(
          container=>{
            let containerArray = [];
            categories.map(
              category=>{
                if(category.location==container.title) containerArray.push(category);
              }
            )
            let obj = {
              title:container.title,
              icon:container.icon,
              categories:containerArray,
              type:"container"
            };
            this.menu.push(obj);
          }
        )
    
        categories.map(
          category=>{
            if(category.location=="main"){
              let obj = {
                title:category.title,
                icon:category.icon,
                type:"category"
              };
              this.menuCategories.push(obj);
            }
          }
        )
      },
      err=>console.log(err)
    )


  }

  ngOnInit(){
    this.router.events.subscribe(event=>{
      if (event instanceof NavigationEnd){
        if (event.url.indexOf('/product')<0){
          this.title.setTitle('GM sanitarios')
        }
      }
    })
    if (isPlatformBrowser(this.platformId)){
      if (localStorage.getItem('token')){
        sessionStorage.setItem('token', localStorage.getItem('token'))
      }
      if(sessionStorage.getItem('token')){
        this.userService.getUser().subscribe(
          resp=>{
            if(resp) this.user = resp;
          },
          err=>{
            console.log(err);
            sessionStorage.removeItem('token');
            localStorage.removeItem('token');
            this.user = undefined;
          }
        )
      }
      this.orderService.getConfig().subscribe(
        resp=>{
          this.config = resp;
          if (!sessionStorage.getItem('gm_popup_check')){
            if (this.config.activePopup){
              Swal.fire({
                icon:'info',
                text:this.config.textPopup,
                confirmButtonText:'Cerrar'
              })
              sessionStorage.setItem('gm_popup_check', 'true');
            }
          }
        },
        err=>console.log(err)
      )
      initLoad();
    }

  }

  scrollUp(){
    if (isPlatformBrowser(this.platformId)){
      let scrollToTop = window.setInterval(() => {
        let pos = window.pageYOffset;
        if (pos > 0) {
            window.scrollTo(0, pos - 20);
        } else {
            window.clearInterval(scrollToTop);
        }
      }, 16);
    }

  }

  generalSearch(){
    if(this.searchText.length>0){
      this.router.navigate(['viewProducts'], {queryParams:{action:'general', value:this.searchText}});
    }
  }

  deleteProduct(i){
    this.cartService.deleteProduct(i);
  }

  get getTotal(){
    return this.cartService.getTotal()
  }

  get getProducts(){
    return this.cartService.getProducts()
  }

  handleLogin(){
    if (isPlatformBrowser(this.platformId)){
      let token = sessionStorage.getItem('token');
      if (!token){
        $('#loginModal').modal('show');
      }
    }
  }

  login(){
    if (isPlatformBrowser(this.platformId)){
      if (!this.userForm.valid){
        Swal.fire({
          icon:'info',
          title:'Por favor, revisá tus datos',
          customClass:{
            container:'front'
          }
        })
      }else{
        this.userService.login(this.userForm.value).subscribe(
          resp=>{
            if(this.userForm.value.rememberMe) {
              localStorage.setItem('token', resp.token);
              sessionStorage.setItem('token', resp.token);
          }
            else sessionStorage.setItem('token', resp.token);
            this.userService.getUser().subscribe(
              resp=>{
                this.user = resp;
                $('#loginModal').modal('hide');
              },
              err=>console.log(err)
            );
          },  
          err=>{
            Swal.fire({
              icon:'error',
              title:err.error.msg,
              customClass:{
                container:'front'
              }
            });
          }
        )
      }
    }
  }

  logout(){
    if (isPlatformBrowser(this.platformId)){
      sessionStorage.removeItem('token');
      localStorage.removeItem('token');
      window.location.reload();
    }
  }

  handleCheckout(){
    if (!this.user){
      $('#loginModal').modal('show');
    }else{
      this.router.navigate(['checkout'])
    }
  }

  hideMenu(){
      $('.categories_btn').addClass('collapsed');
      $('.categories_btn,.side_navbar_toggler').attr('aria-expanded', 'false');
      $('#navCatContent,#navbarSidetoggle').removeClass('show');
  }

  restorePassword(){
    if (isPlatformBrowser(this.platformId)){
      $('#loginModal').modal('hide');
      this.userService.restorePassword({email:this.restoringEmail}).subscribe(
        resp=>{
          Swal.fire({
            text:"Se ha enviado un e-mail para recuperar tu contraseña. Por favor, revisá tu bandeja de entrada y spam",
            icon:'info'
          }).then(
            resp=>location.reload()
          )
        },
        err=>console.log(err)
      )
    }
  }

}
