import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { UploadService } from 'src/app/services/upload.service';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.css']
})
export class CardsComponent implements OnInit {

  public creditCards = []
  public qnt = 0;
  public description = "cuotas de";
  public postDescription = "";
  public factor = 0;
  public imageUrl = "";
  public tempImg;
  public file;
  public isUploading = false;

  constructor(
    private orderService:OrderService,
    private uploadService:UploadService
  ) { 
    this.orderService.getCreditCards().subscribe(
      resp=>{
        this.creditCards = resp;
      },
      err=>console.log(err)
    )
  }

  ngOnInit(): void {
  }

  removeCreditCard(i, card){
    this.orderService.deleteCreditCard(card).subscribe(
      resp=>{
        this.creditCards.splice(i,1);
      },
      err=>console.log(err)
    )
  }

  async addCreditCard(){
    if(this.file){
      let imageUrl;
      this.isUploading = true;
      imageUrl = await this.uploadService.uploadImage(this.file).toPromise();
      this.isUploading = false;
      this.imageUrl = imageUrl.secure_url;
    }
    let obj = {
      description: this.description,
      postDescription: this.postDescription,
      factor:this.factor,
      qnt:this.qnt,
      imageUrl:this.imageUrl
    }
    this.orderService.createCreditCard(obj).subscribe(
      resp=>{
        location.reload();
      },
      err=>console.log(err)
    )

  }

  getFile(e){
    var reader = new FileReader();
    reader.onloadend = ()=>{
      this.tempImg = reader.result;
    }
    reader.readAsDataURL(e.target.files[0]);
    this.file=e.target.files[0];
  }

}
