import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { FormBuilder, FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import {NgxPaginationModule} from 'ngx-pagination';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ShareModule } from 'ngx-sharebuttons';

import { AppComponent } from './app.component';
import { MainComponent } from './pages/main/main.component';
import { CreateProductComponent } from './pages/create-product/create-product.component';
import { BannersConfigComponent } from './pages/banners-config/banners-config.component';
import { CategoriesConfigComponent } from './pages/categories-config/categories-config.component';
import { ViewProductsComponent } from './pages/view-products/view-products.component';
import { EditProductsComponent } from './pages/edit-products/edit-products.component';
import { TitleFilterPipe } from './pipes/title-filter.pipe';
import { CartService } from './services/cart.service';
import { ProductComponent } from './pages/product/product.component';
import { DescriptionFilterPipe } from './pipes/description-filter.pipe';
import { IdFilterPipe } from './pipes/id-filter.pipe';
import { TrademarkFilterPipe } from './pipes/trademark-filter.pipe';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { RegisterComponent } from './pages/register/register.component';
import { MyAccountComponent } from './pages/myAccount/my-account.component';
import { ShippingValuesComponent } from './pages/shippingValues/shipping-values.component';
import { NgxEditorModule } from 'ngx-editor';
import { OrdersComponent } from './pages/orders/orders/orders.component';
import { UserPipe } from './pipes/user.pipe';
import { UsersComponent } from './pages/users/users.component';
import { CardsComponent } from './pages/cards/cards.component';
import { RestoreComponent } from './pages/restore/restore.component';
import { AboutComponent } from './pages/about/about.component';
import { QrCodeModule } from 'ng-qrcode';
import { QrGeneratorComponent } from './pages/qr-generator/qr-generator.component';

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    CreateProductComponent,
    BannersConfigComponent,
    CategoriesConfigComponent,
    ViewProductsComponent,
    EditProductsComponent,
    TitleFilterPipe,
    ProductComponent,
    DescriptionFilterPipe,
    IdFilterPipe,
    TrademarkFilterPipe,
    CheckoutComponent,
    RegisterComponent,
    MyAccountComponent,
    ShippingValuesComponent,
    OrdersComponent,
    UserPipe,
    UsersComponent,
    CardsComponent,
    RestoreComponent,
    AboutComponent,
    QrGeneratorComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule,
    GooglePlaceModule,
    NgxEditorModule,
    ShareModule,
    QrCodeModule
  ],
  providers: [
    FormBuilder,
    CartService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
