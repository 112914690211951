import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import { ExportToCsv } from 'export-to-csv';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
declare var $;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styles: [
  ]
})
export class OrdersComponent implements OnInit {
  public config;
  public orders = [];
  public userFilter="";
  public p = 1;
  public popUpText = '';
  public selectedOrder;
  constructor(
    private orderService:OrderService,
    private route:ActivatedRoute,
  ) { 
    let userId=route.snapshot.queryParamMap.get('id');
    if (userId){
      this.orderService.getUserOrders({_id:userId}).subscribe(
        resp=>{
          this.orders = resp
        },
        err=>console.log(err)
      )
    }else{
      this.orderService.getOrders().subscribe(
        resp=>{
          this.orders = resp;
        },
        err=>console.log(err)
      )
    }
  }

  ngOnInit(): void {
    this.orderService.getConfig().subscribe(
      resp=>{
        if (!resp) this.config = {activeCheckout:true}
        else {
          this.config = resp;
          this.popUpText = this.config.textPopup;
        }
      },
      err=>console.log(err)
    )
  }

  deleteOrder(o){
    Swal.fire({
      title:"¿Borrar pedido?",
      icon:"question",
      showCancelButton:true,
      confirmButtonText:"Si",
      cancelButtonText:"No"
    }).then(
      resp=>{
        if(resp.isConfirmed){
          this.orderService.deleteOrder(o).subscribe(
            resp=>{
              Swal.fire({
                title:"Borrado",
                icon:"success"
              }).then(
                resp=>window.location.reload()
              )
            },
            err=>console.log(err)
          )
        }
      }
    )
  }

  exportCsv(o){
    this.orderService.getOrder(o).subscribe(
      resp=>{
        var data = [];
        resp.items.map(
          i=>{
              let obj = {
                id:i.id,
                cantidad:i.qnt,
                cliente:resp.userName,
                email:resp.userEmail,
                telefono:resp.userPhone,
                direccion:resp.direccion,
                precio_envio:resp.shippingPrice
              };
              data.push(obj);
          }
      )
        const options = { 
          fieldSeparator: ';',
          quoteStrings: '"',
          decimalSeparator: '.',
          showLabels: true, 
          useTextFile: false,
          filename:resp.userName+"_"+ Date.now(),
          useBom: true,
          useKeysAsHeaders: true,
        };
         
        const csvExporter = new ExportToCsv(options);
         
        csvExporter.generateCsv(data);

      },
      err=>console.log(err)
    )
  }

  showItems(o){
    this.selectedOrder = o;
    $("#itemsModal").modal("show");
  }

  showOrder(o){
    this.selectedOrder = o;
    $("#orderModal").modal("show");
  }

  changeState(){
    this.config.activeCheckout = !this.config.activeCheckout
    this.orderService.updateConfig(this.config).subscribe(
      resp=>{
        Swal.fire("Estado modificado");
      },
      err=>console.log(err)
    )
  }

  changePopupState(){
    this.config.activePopup = !this.config.activePopup
    this.orderService.updateConfig(this.config).subscribe(
      resp=>{
        Swal.fire("Estado modificado");
      },
      err=>console.log(err)
    )
  }

  updatePopupText(){
    this.config.textPopup = this.popUpText;
    this.orderService.updateConfig(this.config).subscribe(
      resp=>{
        Swal.fire("Estado modificado");
      },
      err=>console.log(err)
    )
  }

}
