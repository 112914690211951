import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-qr-generator',
  templateUrl: './qr-generator.component.html',
  styleUrls: ['./qr-generator.component.css']
})
export class QrGeneratorComponent implements OnInit {

  public codes: string[] = [];

  public qrForm = this.fb.group({
    name: [''],
    qty:[0]
  })

  constructor(
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
  }

    
  generate() {
    this.codes = [];
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; this.qrForm.value.qty > i; i++){
      let result = '';
      const charactersLength = characters.length;
      for ( let i = 0; i < 10; i++ ) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.codes.push(result);
    }
  }

  
}
