<div style="min-height: 100vh;" class="custom-container mt-5 mb-5">
    <div class="col-lg-7 pt-5 offset-lg-3">
        <div class="section" style="padding-top: 0;">
            <div *ngIf="product" class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6 mb-4 mb-md-0">
                      <div class="product-image">
                            <div class="product_img_box">
                                <img class="fixed-photo" [src]="selectedPhoto=='no-image'?'../../../assets/images/gm-logo.png':selectedPhoto" alt="{{product.title}}" />
                            </div>
                            <div class="product_gallery_item slick_slider" data-slides-to-show="4" data-slides-to-scroll="1" data-infinite="false">
                                <div *ngIf="product.imageUrl!='no-image'" class="item">
                                    <a href="javascript:void(0)" style="width: 150; height: 160;" (click)="setPhoto(product.imageUrl)" class="product_gallery_item active" [data-image]="product.imageUrl">
                                        <img class="fixed-photo-sm" [src]="product.imageUrl" alt="p2" />
                                    </a>
                                </div>
                                <div *ngIf="product.imageUrl2!='no-image'" class="item">
                                    <a href="javascript:void(0)" class="product_gallery_item" [data-image]="product.imageUrl2">
                                        <img class="fixed-photo-sm" style="width: 150; height: 160;" [src]="product.imageUrl2" (click)="setPhoto(product.imageUrl2)" alt="{{product.title}}" />
                                    </a>
                                </div>
                                <div *ngIf="product.imageUrl3!='no-image'" class="item">
                                    <a href="javascript:void(0)" class="product_gallery_item" [data-image]="product.imageUrl3">
                                        <img class="fixed-photo-sm" style="width: 150; height: 160;" [src]="product.imageUrl3" (click)="setPhoto(product.imageUrl3)" alt="{{product.title}}" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="pr_detail">
                            <div class="product_description">
                                <h4 class="product_title"><a >{{product.title}}</a></h4>
                                <div class="product_price">
                                    <span *ngIf="!product.clusterContainer&&product.minStock>0" class="price">${{product.price}}</span>
                                </div>
                                <div style="padding:0px;" class="pr_desc col-12">
                                    <p>{{product.longDescription}}</p>
                                </div>
                            </div>
                            <hr />
                            <div class="cart_extra justify-content-center">
                                <div *ngIf="product.cluster" class="col-12 mb-2">
                                    <select [(ngModel)]="clusterSelector" (change)="clusterSelect($event)" [ngModelOptions]="{standalone:true}" class="form-control">
                                        <option disabled value="">{{product.clusterSelector}}</option>
                                        <option *ngFor="let p of productsOnCluster" [value]="p.id">{{p.title}}</option>
                                    </select>
                                </div>
                                <div *ngIf="!product.clusterContainer" class="cart-product-quantity col-12 mb-2">
                                    <div class="row justify-content-center">
                                        <div class="quantity">
                                            <input type="number" [(ngModel)]="qnt" id="qnt" class="qty" min="1" [max]="product.stock-product.minStock">
                                            <span style="display: table-cell; vertical-align: middle;" for="qnt">Cantidad</span>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!product.clusterContainer" class="cart_btn">
                                    <button *ngIf="product.published&&product.stock>product.minStock&&product.minStock>0" class="btn btn-fill-out btn-addtocart" (click)="addToCart()" type="button"><i class="icon-basket-loaded"></i> Agregar al carrito</button>
                                    <a *ngIf="!product.published||product.minStock==0||product.stock==0||product.stock<=product.minStock" [href]="'https://api.whatsapp.com/send?phone=542235361844&text='+environment.baseUrl+'/product/'+product.id" target="_blank" class="btn btn-fill-out btn-addtocart" type="button"><i class="icon-basket-loaded"></i> Consulte disponibilidad</a>
                                </div>
                                <ul style="margin-top: 20px;" *ngIf="!product.clusterContainer&&product.minStock>0" class="list-group">
                                    <li class="list-group-item active cardHeader">¡Aprovechá nuestras promociones bancarias!</li>
                                    <li *ngFor="let card of cards" class="list-group-item">
                                        <div class="row">
                                            <div style="margin-left: 10px ;margin-right: 10px;">
                                                <img [src]="card.imageUrl" width="40" alt=""> 
                                            </div>
                                            <div>
                                                <small><b> {{card.qnt}} {{card.description}} ${{(product.price*card.factor/card.qnt) | number:'0.0-2'}}</b></small>
                                                <small style="font-weight: 300; color: '#5c5e64'; display: block; text-align: center;">{{card.postDescription}}</small>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <hr />
                            <ul class="product-meta">
                                <li>ID: <a>{{product.id}}</a></li>
                                <li>Categorias: <a href="javascript:void(0)" [routerLink]="['../../viewProducts']"[queryParams]="{action:'category', value:c}" *ngFor="let c of product.categories; index as i">{{c}} </a></li>
                            </ul>
                            
                            <div class="product_share">
                                <span>Compartir:</span>
                                <ul class="social_icons">
                                    <li><a href="javascript:void(0)" [image]="product.imageUrl" [description]="product.title" [title]="product.title" shareButton="facebook"><i class="fa-brands fa-facebook"></i></a></li>
                                    <li><a href="javascript:void(0)" [image]="product.imageUrl" [description]="product.title" [title]="product.title" shareButton="twitter"><i class="fa-brands fa-twitter"></i></a></li>
                                    <li><a href="javascript:void(0)" [image]="product.imageUrl" [description]="product.title" [title]="product.title" shareButton="whatsapp" ><i class="fa-brands fa-whatsapp"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="large_divider clearfix"></div>
                    </div>
                </div>
                <div *ngIf="product.tags.length>0||product.info.length>0" class="row">
                    <div class="col-12">
                        <div class="tab-style3">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" id="Description-tab" data-toggle="tab" href="#Description" role="tab" aria-controls="Description" aria-selected="true">Información adicional</a>
                                </li>
                            </ul>
                            <div class="tab-content shop_info_tab">
                                  <div class="tab-pane fade show active" id="Description" role="tabpanel" aria-labelledby="Description-tab">
                                    <table class="table table-bordered">
                                        <tr *ngFor="let tag of product.tags">
                                            <td>{{tag.tag}}</td>
                                            <td>{{tag.value}}</td>
                                        </tr>
                                    </table>
                                    <div id="info" #info>

                                    </div>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="large_divider clearfix"></div>
                    </div>
                </div>
                <div *ngIf="product.related.length>0" class="row">
                    <div class="col-12">
                        <div class="heading_s1">
                            <h3>Productos relacionados</h3>
                        </div>
                        <div class="releted_product_slider carousel_slider owl-carousel owl-theme" data-margin="20" data-responsive='{"0":{"items": "1"}, "481":{"items": "2"}, "768":{"items": "3"}, "1199":{"items": "4"}}'>
                            <div *ngFor="let item of related" class="item">
                                <div class="product">
                                    <div class="product_img">
                                        <a href="javascript:void(0)">
                                            <img [src]="item.imageUrl=='no-image'?'../../../assets/images/gm-logo.png':item.imageUrl" alt="">
                                        </a>
                                        <div class="product_action_box">
                                            <ul class="list_none pr_action_btn">
                                                <li class="add-to-cart"><a href="javascript:void(0)" (click)="quickAdd(item)"><i class="icon-basket-loaded"></i> Agregar al carrito</a></li>
                                                <li><a [href]="'../product/'+item.id" class="popup-ajax"><i class="lnr lnr-eye"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="product_info">
                                        <h6 class="product_title"><a>{{item.title}}</a></h6>
                                        <div class="product_price">
                                            <span *ngIf="item.minStock>0" class="price">${{item.price}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>