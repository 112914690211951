import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-view-products',
  templateUrl: './view-products.component.html',
  styleUrls: ['./view-products.component.css']
})
export class ViewProductsComponent implements OnInit {

  public loaded = false;
  public action;
  public products = [];
  public originalProductsArray = [];
  public environment = environment;
  public p = 1;
  constructor(
    private productService:ProductService,
    private route:ActivatedRoute,
    private cartService:CartService
  ) { }

  ngOnInit(): void {
    let value;
    this.route.queryParams.subscribe(
      params=>{
        this.action = params['action'];
        value = params['value'];
        if (this.action=="category"){
          this.productService.getProductsByCategory(value).subscribe(
            resp=>{
              this.products = resp;
              this.originalProductsArray = resp;
              this.loaded = true;
            },
            err=>console.log(err)
          )
        }
        if (this.action=="trademark"){
          this.productService.getProductsByTrademark(value).subscribe(
            resp=>{
              this.products = resp;
              this.originalProductsArray = resp;
              this.loaded = true;
            },
            err=>console.log(err)
          )
        }
        if (this.action=="general"){
          this.productService.getProductsByText(value).subscribe(
            resp=>{
              this.products = resp;
              this.originalProductsArray = resp;
              this.loaded = true;
            },
            err=>console.log(err)
          )
        }
      }
    )
  }

  addToCart(p){
    let obj = {
      title:p.title,
      price:p.price,
      stock:p.stock,
      minStock:p.minStock,
      qnt:1,
      imageUrl:p.imageUrl=='no-image'?'../../../assets/images/gm-logo.png':p.imageUrl,
      id:p.id
    }
    this.cartService.addProduct(obj);
    Swal.fire("Producto agregado al carrito");
  }

  sort(o){
    if(o.target.value=="default") {
      window.location.reload();
    }
    if(o.target.value=="title"){
      this.products.sort((a,b)=>{
        if(a.title>b.title) return 1;
        else return -1;
      })
    }
  }

  pageChange(e){
    this.p = e;
    scrollTo(0,0);
  }

}
