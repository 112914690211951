<div style="min-height: 100vh;" class="custom-container">
    <div *ngIf="bannerConfig" class="col-lg-7 pt-5 offset-lg-3">
        <h2 class="mt-5 mb-5">Slider principal</h2>
        <div *ngFor="let slide of bannerConfig.data.mainBannerProducts; index as i">
            <h4>Slide {{i+1}} <small><a href="javascript:void(0)" (click)="removeSlide(i)"><i class="ti-close"></i></a></small> </h4>
            <div class="row">
                <div class="form-group col-md-6">
                    <input name="title" [(ngModel)]="bannerConfig.data.mainBannerProducts[i].title" class="form-control" placeholder="Título" type="text">
                    <label for="title"><small>Titulo</small></label>
                </div>
                <div class="form-group col-md-6">
                    <input name="description" [(ngModel)]="bannerConfig.data.mainBannerProducts[i].description" class="form-control" placeholder="Descripción" type="text">
                    <label for="description"><small>Descripción</small></label>
                </div>
                <div class="form-group col-md-6">
                    <input name="id" [(ngModel)]="bannerConfig.data.mainBannerProducts[i].id" class="form-control" placeholder="ID / Categoría" type="text">
                    <label for="id"><small>ID / Categoría</small></label>
                </div>
                <div class="form-group col-md-6">
                    <input name="image" (change)="mainBannerImage($event,i)" class="form-control" placeholder="URL imágen" type="file">
                    <label for="image"><small>Imagen (825x550 imágen alineada a la derecha)</small></label>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" checked="false" [(ngModel)]="bannerConfig.data.mainBannerProducts[i].category" type="checkbox" name="category" [id]="'category'+i">
                                <label class="form-check-label label_info" [for]="'category'+i"><span>Es categoría</span></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <button type="button" class="btn btn-dark btn-radius" (click)="addSlide()" name="submit" ><i class="ti-plus"></i>Agregar slide</button>
        </div>
        <div class="col-12" style="text-align:right">
            <a href="javascript:void(0)" class="btn btn-fill-out checkout mb-5" style="margin-right: 0px;" (click)="update()">Actualizar</a>
        </div>
        <h2 class="mt-5 mb-5">Banner principal lateral</h2>
        <h4>Banner 1</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.sideMainBanner[0].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.sideMainBanner[0].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID / Categoría</small></label>
            </div>
            
            <div class="form-group col-md-6">
                <input name="image" (change)="sideMainBannerImage($event,0)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (250x355)</small></label>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="chek-form">
                        <div class="custome-checkbox">
                            <input class="form-check-input" checked="false" [(ngModel)]="bannerConfig.data.sideMainBanner[0].category" type="checkbox" name="category" [id]="'category 990'">
                            <label class="form-check-label label_info" [for]="'category 990'"><span>Es categoría</span></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h4>Banner 2</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.sideMainBanner[1].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.sideMainBanner[1].id" class="form-control" placeholder="ID / Categoría" type="text">
                <label for="id"><small>ID / Categoría</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="sideMainBannerImage($event,1)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (250x355)</small></label>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="chek-form">
                        <div class="custome-checkbox">
                            <input class="form-check-input" checked="false" [(ngModel)]="bannerConfig.data.sideMainBanner[1].category" type="checkbox" name="category" [id]="'category 991'">
                            <label class="form-check-label label_info" [for]="'category 991'"><span>Es categoría</span></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h2 class="mt-5 mb-5">Producto solo izquierdo</h2>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.singlePromo.title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.singlePromo.id" class="form-control" placeholder="ID / Categoría" type="text">
                <label for="id"><small>ID / Categoría</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="leftBannerImage($event)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (385x535)</small></label>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="chek-form">
                        <div class="custome-checkbox">
                            <input class="form-check-input" checked="false" [(ngModel)]="bannerConfig.data.singlePromo.category" type="checkbox" name="category" [id]="'category99'">
                            <label class="form-check-label label_info" [for]="'category99'"><span>Es categoría</span></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12" style="text-align:right">
            <a href="javascript:void(0)" class="btn btn-fill-out checkout mb-5" style="margin-right: 0px;" (click)="update()">Actualizar</a>
        </div>
        <h2 class="mt-5 mb-5">Nuevos productos</h2>
        <h4>Producto 1</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.newProducts[0].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.newProducts[0].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,0, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(0,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,0, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(0,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <h4>Producto 2</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.newProducts[1].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>

            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.newProducts[1].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,1, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(1,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,1, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(1,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <h4>Producto 3</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.newProducts[2].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>

            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.newProducts[2].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,2, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(2,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,2, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(2,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <h4>Producto 4</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.newProducts[3].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>

            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.newProducts[3].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,3, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(3,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,3, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(3,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <h4>Producto 5</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.newProducts[4].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>

            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.newProducts[4].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,4, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(4,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="newProductsImage($event,4, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeNewProductImage(4,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <h2 class="mt-5 mb-5">Productos destacados</h2>
        <h4>Producto destacado 1</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.featuredProducts[0].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>

            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.featuredProducts[0].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,0, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(0,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,0, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(0,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <h4>Producto destacado 2</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.featuredProducts[1].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>

            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.featuredProducts[1].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,1, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(1,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,1, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(1,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <h4>Producto destacado 3</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.featuredProducts[2].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>

            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.featuredProducts[2].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,2, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(2,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,2, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(2,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <h4>Producto destacado 4</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.featuredProducts[3].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>

            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.featuredProducts[3].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,3, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(3,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,3, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(3,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <h4>Producto destacado 5</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="title" [(ngModel)]="bannerConfig.data.featuredProducts[4].title" class="form-control" placeholder="Título" type="text">
                <label for="title"><small>Titulo</small></label>
            </div>

            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.featuredProducts[4].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,4, false)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(4,false)" class="text-danger">Borrar</a></small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="featuredProductsImage($event,4, true)" class="form-control" placeholder="URL imágen transición" type="file">
                <label for="imageH"><small>URL imágen transición (540x600)<a href="javascript:void(0)" (click)="removeFeaturedProductImage(4,true)" class="text-danger">Borrar</a></small></label>
            </div>
        </div>
        <div class="col-12" style="text-align:right">
            <a href="javascript:void(0)" class="btn btn-fill-out checkout mb-5" style="margin-right: 0px;" (click)="update()">Actualizar</a>
        </div>
        <h2 class="mt-5 mb-5">Banner inferior</h2>
        <h4>Banner 1</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.lastMainBanner[0].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="lastMainBannerImage($event,0)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x300)</small></label>
            </div>
        </div>
        <h4>Banner 2</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.lastMainBanner[1].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="lastMainBannerImage($event,1)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x300)</small></label>
            </div>
        </div>
        <h4>Banner 2</h4>
        <div class="row">
            <div class="form-group col-md-6">
                <input name="id" [(ngModel)]="bannerConfig.data.lastMainBanner[2].id" class="form-control" placeholder="ID" type="text">
                <label for="id"><small>ID</small></label>
            </div>
            <div class="form-group col-md-6">
                <input name="image" (change)="lastMainBannerImage($event,2)" class="form-control" placeholder="URL imágen" type="file">
                <label for="image"><small>URL imágen (540x300)</small></label>
            </div>
        </div>
        <h2 class="mt-5 mb-5">Marcas</h2>
        <div *ngFor="let trademark of bannerConfig.data.logos; index as i">
            <div class="row">
                <div class="form-group col-md-6">
                    <input name="id" [(ngModel)]="bannerConfig.data.logos[i].title" class="form-control" placeholder="Título" type="text">
                    <label for="id"><small>Título</small></label>
                </div>
                <div class="form-group col-md-5">
                    <input name="image" (change)="tImage($event,i)" class="form-control" placeholder="URL imágen" type="file">
                    <label for="image"><small>URL imágen (163x85)</small></label>
                </div>
                <div class="col-md-1 pt-3">
                    <a href="javascript:void(0)" (click)="removeTrademark(i)"><i class="ti-close"></i></a>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
                <button type="button" class="btn btn-dark btn-radius" (click)="addTrademark()" name="submit" ><i class="ti-plus"></i>Agregar marca</button>
        </div>
        <div class="col-12" style="text-align:right">
                <a href="javascript:void(0)" class="btn btn-fill-out checkout mb-5" style="margin-right: 0px;" (click)="update()">Actualizar</a>
        </div>

    </div>
</div>