import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-categories-config',
  templateUrl: './categories-config.component.html',
  styleUrls: ['./categories-config.component.css']
})
export class CategoriesConfigComponent implements OnInit {

  public containerTitle;
  public containerIcon;
  public containersArray = [];
  public categoryTitle;
  public categoryIcon;
  public id;
  public location="default";
  public categoriesArray = [];

  constructor(
    private productService:ProductService
  ) { 
    productService.getCategories().subscribe(
      resp=>{
        this.id = resp._id;
        this.categoriesArray =  resp.data.categories;
        this.containersArray =  resp.data.containers;
      },
      err=>console.log(err)
    )
  }

  ngOnInit(): void {
  }

  addContainer(){
    let obj = {
      title:this.containerTitle,
      icon:this.containerIcon
    };
    this.containersArray.push(obj);
    this.containerIcon="";
    this.containerTitle="";
  }

  deleteContainer(index){
    this.containersArray.splice(index, 1);
  }

  addCategory(){
    let obj = {
      title:this.categoryTitle,
      icon:this.categoryIcon,
      location:this.location
    };
    this.categoriesArray.push(obj);
    this.categoryIcon="";
    this.categoryTitle="";
    this.location="default";
  }

  deleteCategory(index){
    this.categoriesArray.splice(index, 1);
  }

  update(){
    let obj = {};
    if (this.id){
      obj = {
        data:{
          containers:this.containersArray,
          categories:this.categoriesArray
        },
        _id:this.id
      };
    }else{
      obj = {
        data:{
          containers:this.containersArray,
          categories:this.categoriesArray
        },
      }
    }
    this.productService.updateCategories(obj).subscribe(
      resp=>{
        Swal.fire("Menú actualizado").then(
          resp=>window.location.reload()
        )
      },
      err=>console.log(err)
    )
  }

}
