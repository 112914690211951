
<div class="col-md-7 pt-5 offset-md-3">
    <div class="login_register_wrap section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-md-10">
                    <div class="login_wrap">
                        <div class="padding_eight_all bg-white">
                            <div>
                                <h3>Crear una cuenta</h3>
                            </div>
                            <form method="post" [formGroup]="userForm">
                                <div class="form-group">
                                    <input type="text" formControlName="userName" required="" class="form-control" name="name" placeholder="Nombre y apellido">
                                </div>
                                <div class="form-group">
                                    <input type="text" formControlName="email" required="" class="form-control" name="email" placeholder="Email">
                                </div>
                                <div class="form-group">
                                    <input type="text" formControlName="phone" required="" class="form-control" name="phone" placeholder="Teléfono">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" formControlName="password" required="" type="password" name="password" placeholder="Contraseña">
                                </div>
                                <div class="form-group">
                                    <input class="form-control" formControlName="confirmPassword" required="" type="password" name="password" placeholder="Confirmar contraseña">
                                </div>

                                <div class="form-group">
                                    <button [disabled]="!userForm.valid" type="button" (click)="submit()" class="btn btn-fill-out btn-block" name="register">Registrarme</button>
                                </div>
                            </form>
                            <div class="form-note text-center">¿Ya tenés una cuenta? <a href="javascript:void(0)">Ingresá</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>