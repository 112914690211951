<div class="section">
	<div class="container">
    	<div class="row align-items-center">
        	<div class="col-lg-5 offset-lg-2">
            	<div class="about_img scene mb-4 mb-lg-0">
                    <img src="../../../assets/images/gm-front.jpg" alt="gm sanitarios mar del plata"/>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="heading_s1">
                    <h2>GM Sanitarios Mar del Plata</h2>
                </div>
                <p>Desde hace más de 38 años nuestro compromiso como empresa familiar es brindar a nuestros clientes la mejor atención personalizada, el asesoramiento y guía en todos sus proyectos de construcción.</p>
                <p><b>Siempre brindado al mercado marplatense las marcas líderes en el mercado</b></p>
            </div>
            <div class="col-lg-10 offset-lg-2 mt-5">
                <div class="heading_s1">
                    <h3>Climatización, distribuidores oficiales Peisa Ofitt</h3>
                </div>
                <p>Empresa líder en soluciones integrales de climatización y agua caliente sanitaria, con la mejor garantía y el respaldo de FV.</p>
                <div class="heading_s1">
                    <h3>Grifería FV</h3>
                </div>
                <p>FV es una empresa dedicada a la fabricación de grifería y accesorios para el baño y la cocina, sistemas para la conducción de agua y gas, y, sistemas de climatización, comprometida en brindar a sus clientes productos confiables que cumplan con los más altos estándares de calidad, así como de proveer un servicio de post venta y asesoramiento de excelencia.</p>
                <div class="heading_s1">
                    <h3>Sanitarios Ferrum</h3>
                </div>
                <p>Líder en el mercado, cuenta con más de 100 años de historia, una empresa que se dedica al desarrollo, producción y comercialización de productos sanitarios. </p>
                <div class="heading_s1">
                    <h3>Grupo Dema. Conducción de Agua con termofusion, AqcuaSystem</h3>
                </div>
                <p>El sistema líder para conducción de agua en Polipropileno Copolímero Random (tipo 3)</p>
                <div class="heading_s1">
                    <h3>Desagües, Duratop X</h3>
                </div>
                <p>El más alto rango de calidad en Desagües</p>
                <div class="heading_s1">
                    <h3>Conducción de Gas con termofusión, Sigas</h3>
                </div>
                <p>SIGAS termofusión es un sistema de conducción y distribución interna de gas natural y gases licuados de petróleo para viviendas, Industrias y todo tipo de edificios, producido en polietileno y acero, con unión por termofusión.</p>
                <div class="heading_s1">
                    <h3>Sistemas de bombeo, Distribuidores oficiales Pluvius</h3>
                </div>
                <p>Es una marca del Grupo Latyn que cuenta con más de 40 años en el mercado,  brindando soluciones integrales en sistemas de bombeo de agua.</p>
                
            </div>
        </div>
    </div>
</div>