import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http:HttpClient
  ) { }

  register(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/users/createUser`, data);
  }

  login(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/users/login`, data);
  }

  updateUser(data):Observable<any>{
    return this.http.put(`${environment.apiBaseUrl}/users/updateUser`, data);
  }

  getUser():Observable<any>{
    let token = sessionStorage.getItem('token');
    let headers = new HttpHeaders({
      'token':token
    });
    return this.http.get(`${environment.apiBaseUrl}/users/getUser`,{
      headers: headers
    });
  }

  getUsers():Observable<any>{
    return this.http.get(`${environment.apiBaseUrl}/users/getUsers`);
  }

  deleteUser(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/users/deleteUser`, data);
  }

  restorePassword(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/users/requestNewPassword`, data);
  }

  registerRaffle(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/users/registerRaffle`, data);
  }
}
