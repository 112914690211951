<div style="min-height: 100vh;" class="custom-container mt-5 mb-5">
    <div class="col-lg-7 pt-5 offset-lg-3">
        <h2 class="mt-5 mb-5">Tarjetas de crédito</h2>
        <div class="row">
            <div class="form-group col-md-4">
                <input name="title" [(ngModel)]="qnt" min="0" class="form-control" placeholder="Cuotas" type="number">
                <label for="title"><small>Cuotas</small></label>
            </div>
            <div class="form-group col-md-4">
                <input name="description" [(ngModel)]="factor" class="form-control" placeholder="Factor de multiplicación" type="number">
                <label for="description"><small>Factor de multiplicación</small></label>
            </div>
            <div class="form-group col-md-4">
                <input name="id" [(ngModel)]="description" class="form-control" placeholder="Descripción" type="text">
                <label for="id"><small>Descripción</small></label>
            </div>
            <div class="form-group col-md-4">
                <input name="id" [(ngModel)]="postDescription" class="form-control" placeholder="Descripción posterior" type="text">
                <label for="id"><small>Descripción posterior</small></label>
            </div>
            <div class="col-md-6">
                <div class="form-group form-floating">
                    <input type="file" (change)="getFile($event)" class="form-control" id="file" name="image" placeholder="Imágen">
                    <label for="file">Imágen</label>
                    <div *ngIf="isUploading" class="spinner-border text-success" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>   
        </div>
        <div class="row justify-content-center">
            <button type="button" class="btn btn-dark btn-radius" style="margin-bottom: 20px;" (click)="addCreditCard()" name="submit" ><i class="ti-plus"></i>Agregar</button>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="table-responsive shop_cart_table">
                	<table class="table">
                    	<thead>
                        	<tr>
                            	<th class="product-thumbnail">&nbsp;</th>
                                <th class="product-name">Descripción</th>
                                <th class="product-name">Descripción posterior</th>
                                <th class="product-price">Factor</th>
                                <th class="product-quantity">Cuotas</th>
                                <th class="product-remove">Remover</th>
                            </tr>
                        </thead>
                        <tbody>
                        	<tr *ngFor="let card of creditCards; index as i">
                            	<td class="product-thumbnail"><img width="40" [src]="card.imageUrl" alt="card"></td>
                                <td >{{card.description}}</td>
                                <td >{{card.postDescription}}</td>
                                <td class="product-price" data-title="Price">{{card.factor}}</td>
                              	<td class="product-subtotal" data-title="Total">{{card.qnt}}</td>
                                <td class="product-remove" data-title="Remove"><a href="javascript:void(0)" (click)="removeCreditCard(i, card)"><i class="ti-close"></i></a></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>