import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ProductService } from 'src/app/services/product.service';
import { UploadService } from 'src/app/services/upload.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-create-product',
  templateUrl: './create-product.component.html',
  styleUrls: ['style.css']
})
export class CreateProductComponent implements OnInit {

  public productForm = this.fb.group({
    id:[''],
    description:[''],
    title:[''],
    longDescription:[''],
    price:[''],
    stock:[''],
    minStock:[''],
    imageUrl:[''],
    imageUrl2:[''],
    imageUrl3:[''],
    videoUrl:[''],
    index:[999],
    visible:[true],
    cluster:[false],
    clusterName:[''],
    clusterSelector:[''],
    categories:[[]],
    related:[[]],
    tradeMark:[''],
    published:[true],
    clusterContainer:[false],
    tags:[[]],
  })

  public file;
  public file2;
  public file3;
  public tempImg;
  public category="";
  public related="";
  public tag="";
  public tagValue="";
  public categoriesArray = [];
  public tagsArray = [];
  public relatedArray = [];
  public categories = [];
  public isUploading = false;

  constructor(
    private fb:FormBuilder,
    private productService:ProductService,
    private uploadService:UploadService
  ) { }

  ngOnInit(): void {
    this.productService.getCategories().subscribe(
      resp=>{
        this.categories = resp.data.categories;
      },
      err=>console.log(err)
    )
  }

  addTag(){
    let obj = {
      tag:this.tag,
      value:this.tagValue
    }
    this.tagsArray.push(obj);
    this.tag = "";
    this.tagValue = "";
  }

  addCategory(){
    this.categoriesArray.push(this.category);
    this.category = "";
  }

  addRelated(){
    this.relatedArray.push(this.related);
    this.related = "";
  }

  deleteTag(index){
    this.tagsArray.splice(index,1);
  }

  deleteCategory(index){
    this.categoriesArray.splice(index,1);
  }

  deleteRelated(index){
    this.relatedArray.splice(index,1);
  }

  getFile(e,n){
    if(n==1){
      var reader = new FileReader();
      reader.onloadend = ()=>{
        this.tempImg = reader.result;
      }
      reader.readAsDataURL(e.target.files[0]);
      this.file=e.target.files[0];
    }
    if(n==2){
      var reader = new FileReader();
      reader.onloadend = ()=>{
        this.tempImg = reader.result;
      }
      reader.readAsDataURL(e.target.files[0]);
      this.file2=e.target.files[0];
    }
    if(n==3){
      var reader = new FileReader();
      reader.onloadend = ()=>{
        this.tempImg = reader.result;
      }
      reader.readAsDataURL(e.target.files[0]);
      this.file3=e.target.files[0];
    }
  }

  async submit(){
    let imageUrl;
    if (this.file){
      this.isUploading = true;
      imageUrl = await this.uploadService.uploadImage(this.file).toPromise();
      this.isUploading = false;
      this.productForm.get('imageUrl').setValue(imageUrl.secure_url);
    }else{
      this.productForm.get('imageUrl').setValue("no-image");
    }
    if (this.file2){
      this.isUploading = true;
      imageUrl = await this.uploadService.uploadImage(this.file2).toPromise();
      this.isUploading = false;
      this.productForm.get('imageUrl2').setValue(imageUrl.secure_url);
    }else{
      this.productForm.get('imageUrl2').setValue("no-image");
    }
    if (this.file3){
      this.isUploading = true;
      imageUrl = await this.uploadService.uploadImage(this.file3).toPromise();
      this.isUploading = false;
      this.productForm.get('imageUrl3').setValue(imageUrl.secure_url);
    }else{
      this.productForm.get('imageUrl3').setValue("no-image");
    }
    this.productForm.get('categories').setValue(this.categoriesArray);
    this.productForm.get('related').setValue(this.relatedArray);
    this.productForm.get('tags').setValue(this.tagsArray);
    this.productService.createProduct(this.productForm.value).subscribe(
      resp=>{
        this.productForm.reset();
        this.categoriesArray=[];
        this.tagsArray=[];
        Swal.fire("Producto creado").then(
          resp=>{
            window.location.reload();
          }
        );
      },
      err=>{
        console.log(err)
        Swal.fire("Error: "+err.error.msg);
      }
    )
  }
}
