<div style="min-height: 100vh;" class="custom-container">
    <div class="col-lg-7 pt-5 offset-lg-3">
        <h2 class="mt-5 mb-5">Crear producto</h2>
        <form [formGroup]="productForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" autocomplete="off" formControlName="title" class="form-control" name="title" placeholder="Título">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" autocomplete="off" formControlName="id" class="form-control" name="id" placeholder="ID">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <textarea rows="5" autocomplete="off" formControlName="description" class="form-control" name="description" placeholder="Descripción corta"></textarea>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <textarea rows="5" autocomplete="off" formControlName="longDescription" class="form-control" name="longDescription" placeholder="Descripción larga"></textarea>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="number" formControlName="price" class="form-control" name="price" placeholder="Precio">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="number" formControlName="stock" class="form-control" name="stock" placeholder="Stock">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="number" formControlName="minStock" class="form-control" name="minStock" placeholder="Stock mínimo">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" autocomplete="off" formControlName="videoUrl" class="form-control" name="video" placeholder="You Tube Url">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="number" formControlName="index" class="form-control" name="index" placeholder="Índice">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input type="text" autocomplete="off" formControlName="tradeMark" class="form-control" name="trademark" placeholder="Marca">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <input [readOnly]="!productForm.value.cluster" autocomplete="off" type="text" formControlName="clusterName" class="form-control" name="clusterName" placeholder="Nombre de agrupación">
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <div class="form-group">
                        <input [readOnly]="!productForm.value.cluster" autocomplete="off" type="text" formControlName="clusterSelector" class="form-control" name="clusterName" placeholder="Selector de agrupación">
                        <label for="title">Selector de agrupación</label>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" formControlName="cluster" type="checkbox" name="cluster" id="cluster">
                                <label class="form-check-label label_info" for="cluster"><span>Producto agrupado</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" formControlName="visible" type="checkbox" name="visible" id="visible">
                                <label class="form-check-label label_info" for="visible"><span>Visible</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" formControlName="published" type="checkbox" name="published" id="published">
                                <label class="form-check-label label_info" for="published"><span>Publicado</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" formControlName="clusterContainer" type="checkbox" name="container" id="container">
                                <label class="form-check-label label_info" for="container"><span>Contenedor</span></label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-floating">
                        <input type="file" (change)="getFile($event,1)" class="form-control" id="file" name="image" placeholder="Imágen">
                        <label for="file">Foto principal(540x600)</label>
                        <div *ngIf="isUploading" class="spinner-border text-success" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>                
                <div class="col-md-6">
                    <div class="form-group form-floating">
                        <input type="file" (change)="getFile($event,2)" class="form-control" id="file2" name="image" placeholder="Imágen">
                        <label for="file">Foto 2(540x600)</label>
                        <div *ngIf="isUploading" class="spinner-border text-success" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group form-floating">
                        <input type="file" (change)="getFile($event,3)" class="form-control" id="file3" name="image" placeholder="Imágen">
                        <label for="file">Foto 3(540x600)</label>
                        <div *ngIf="isUploading" class="spinner-border text-success" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-4">
                                <input type="text" [(ngModel)]="tag" [ngModelOptions]="{standalone:true}" class="form-control" name="tags" placeholder="Etiqueta" autocomplete="off">
                            </div>
                            <div class="col-4">
                                <input type="text" [(ngModel)]="tagValue" [ngModelOptions]="{standalone:true}" class="form-control" name="tagValue" placeholder="Valor" autocomplete="off">
                            </div>
                            <div class="col-4">
                                <button type="button" class="btn btn-dark btn-radius" (click)="addTag()" name="submit" value="Submit">Agregar</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="tagsArray.length>0" style="margin-bottom: 10px;">
                        <span class="erasable-span" *ngFor="let tag of tagsArray; index as i" (click)="deleteTag(i)">{{tag.tag}}:{{tag.value}} </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-8">
                                <select [(ngModel)]="category" [ngModelOptions]="{standalone:true}" class="form-control">
                                    <option disabled value="">Agregar categorías</option>
                                    <option *ngFor="let category of categories" [value]="category.title">{{category.title}}</option>
                                </select>
                            </div>
                            <div class="col-4">
                                <button type="button" class="btn btn-dark btn-radius" (click)="addCategory()" name="submit" value="Submit">Agregar</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="categoriesArray.length>0" style="margin-bottom: 10px;">
                        <span class="erasable-span" *ngFor="let category of categoriesArray; index as i" (click)="deleteCategory(i)">{{category}} </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-8">
                                <input type="text" [(ngModel)]="related" [ngModelOptions]="{standalone:true}" class="form-control" name="categories" placeholder="ID's relacionados" autocomplete="off">
                            </div>
                            <div class="col-4">
                                <button type="button" class="btn btn-dark btn-radius" (click)="addRelated()" name="submit" value="Submit">Agregar</button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="relatedArray.length>0" style="margin-bottom: 10px;">
                        <span class="erasable-span" *ngFor="let related of relatedArray; index as i" (click)="deleteRelated(i)">{{related}} </span>
                    </div>
                </div>
                <div class="col-md-6 mb-5">
                    <a href="javascript:void(0)" (click)="submit()" class="btn btn-fill-out btn-radius text-uppercase">Crear producto</a>
                </div>
            </div>
        </form>
    </div>
</div>