<div class="section">
	<div class="container">
    	<div class="row align-items-center">
        	<div class="col-lg-10 offset-lg-2">
                <form [formGroup]="qrForm">
                    <div class="form-group">
                        <input type="text" formControlName="name" class="form-control" name="name" placeholder="Nombre del sorteo">
                    </div>
                    <div class="form-group">
                        <input type="number" formControlName="qty" min="0" class="form-control" name="qty" placeholder="Cantidad de códigos">
                    </div>
    
                    <div class="form-group">
                        <button (click)="generate()" class="btn btn-fill-out btn-block" name="register">Generar</button>
                    </div>
                </form>
            </div>
        </div>
        <div class="row align-items-center">
        	<div class="col-lg-10 offset-lg-2">
                <div class="row">
                    <div *ngFor="let code of codes" class="col-3 qr-container">
                        <qr-code [value]="'https://gmsanitarios.com/?action=register&id='+qrForm.value.name+'&hash='+code" size="200" errorCorrectionLevel="M"></qr-code>
                        <h6 class="text-center">Escaneá el QR para participar</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>