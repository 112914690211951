import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-restore',
  templateUrl: './restore.component.html',
  styleUrls: ['./restore.component.css']
})
export class RestoreComponent implements OnInit {

  public updated = false;

  constructor(
    private route:ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      params=>{
        let obj = {
          token:params.ut,
          password:params.pw
        }
        
        fetch(`${environment.apiBaseUrl}/users/setNewPassword`,{
          method:'post',
          headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          body:JSON.stringify(obj)
        }).then(
          resp=>{
            if(resp.status==200) this.updated = true
            else {
              resp.json().then(
                r=>Swal.fire(r.msg)
              )
            }
          }
        )
      },
      err=>console.log(err)
    )
  }

}
