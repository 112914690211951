import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  public products = [];

  public total=this.getTotal();

  constructor() { }

  addProduct(product){
    let founded = false;
    this.products.map(
      p=>{
        if (p.id==product.id){
          founded = true;
          p.qnt = p.qnt+product.qnt;
          return p;
        }
      }
    )
    if (!founded) this.products.push(product);
    this.total = this.getTotal();
  }

  setNewQuantity(product){
    let founded = false;
    this.products.map(
      p=>{
        if (p.id==product.id){
          founded = true;
          p.qnt = product.qnt;
          return p;
        }
      }
    )
    this.total = this.getTotal();
  }

  deleteProduct(index){
    this.products.splice(index,1);
    this.total = this.getTotal();
  }
  getTotal(){
    let total = 0;
    this.products.map(
      product=>{
        total+=product.price*product.qnt;
      }
    )
    return total;
  }
  getProducts(){
    return this.products;
  }
}
