<div class="col-md-7 pt-5 offset-md-3 mt-5">
    <div class="row justify-content-center">
        <div class="col-11">
            <div class="row">
                <div class="col-md-4">
                    <div class="dashboard_menu">
                        <ul class="nav nav-tabs flex-column" role="tablist">
                            <li class="nav-item">
                              <a class="nav-link active" id="account-detail-tab" data-toggle="tab" href="#account-detail" role="tab" aria-controls="account-detail" aria-selected="true"><i class="ti-id-badge"></i>Mis datos</a>
                            </li>
                          <li class="nav-item">
                            <a class="nav-link" id="orders-tab" data-toggle="tab" href="#orders" role="tab" aria-controls="orders" aria-selected="false"><i class="ti-shopping-cart-full"></i>Pedidos</a>
                          </li>
                          <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0)" (click)="logout()"><i class="ti-lock"></i>Cerrar sesión</a>
                          </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-8 mb-5">
                    <div class="tab-content dashboard_content">
                          <div class="tab-pane fade" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                            <div class="card">
                                <div class="card-header">
                                    <h3>Mis pedidos</h3>
                                </div>
                                <div *ngIf="orders.length>0" class="card-body">
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Fecha</th>
                                                    <th>Envío</th>
                                                    <th>Total</th>
                                                    <th>Acción</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let order of orders">
                                                    <td>{{order.date | date:'shortDate'}}</td>
                                                    <td>{{order.shippingPrice | number: '0.2'}}</td>
                                                    <td>{{order.total | number :'0.2'}}</td>
                                                    <td><a href="javascript:void(0)" data-toggle="modal" data-target="#orderModal" (click)="selectedOrder = order" class="btn btn-fill-out btn-sm">Ver</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                          </div>
                        <div class="tab-pane fade active show" id="account-detail" role="tabpanel" aria-labelledby="account-detail-tab">
                            <div class="card">
                                <div class="card-header">
                                    <h3>Detalles de la cuenta</h3>
                                </div>
                                <div class="card-body">
                                    <form [formGroup]="userForm" name="enq">
                                        <div class="row">
                                            <div class="form-group col-md-12">
                                                <label>Nombre y apellido</label>
                                                <input class="form-control" formControlName="userName" name="name" type="text">
                                             </div>
                                             <div class="form-group col-md-12">
                                                <label>Email </label>
                                                <input  class="form-control" formControlName="email" name="email">
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label>Teléfono</label>
                                                <input formControlName="phone" class="form-control" name="dname" type="text">
                                            </div>
                                            <div class="form-group col-md-12">
                                                <label>Nueva contraseña</label>
                                                <input [(ngModel)]="newPassword" [ngModelOptions]="{standalone:true}" class="form-control" name="dname" type="password">
                                                <button (click)="updatePassword()" class="btn btn-sm btn-warning float-right mt-1">Actualizar contraseña</button>
                                            </div>
                                            <div class="col-md-12">
                                                <button type="button" class="btn btn-fill-out" (click)="update()" name="submit" value="Submit">Guardar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade bd-example-modal-lg" id="orderModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div *ngIf="selectedOrder" class="modal-content p-5">
        <div class="modal-header mb-2">
            <h4 class="modal-title" id="exampleModalLabel">Pedido</h4>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div *ngIf="selectedOrder" class="modal-body">
            <h5>Cliente: {{selectedOrder.userName}}</h5>
            <h5>Email: {{selectedOrder.userEmail}}</h5>
            <h5>Telefono: {{selectedOrder.userPhone}}</h5>
            <h5>Fecha: {{selectedOrder.date | date:"shortDate"}}</h5>
            <h5>Total: $ {{selectedOrder.total | number:'0.2'}}</h5>
            <h5>Envío: {{selectedOrder.shipping==true?"Si":"No"}}</h5>
            <h5>Dirección de envío: {{selectedOrder.address}}</h5>
            <h5>Precio de envío: $ {{selectedOrder.shippingPrice}}</h5>
            <h5>Información adicional: {{selectedOrder.additionalInfo}}</h5>
            <h5>Método de pago: {{selectedOrder.paymentMethod}}</h5>
            <div class="table-responsive mt-5">
                <h3>Items</h3>
                <table class="table">
                    <thead>
                        <tr>
                            <th >ID</th>
                            <th >Título</th>
                            <th >Cantidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let it of selectedOrder.items ; index as i">
                            <td >{{it.id}}</td>
                            <td >{{it.title}}</td>
                            <td >{{it.qnt}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        </div>
    </div>
</div>