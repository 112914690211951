<div style="min-height: 100vh;" class="custom-container mt-5 mb-5">
    <div class="col-lg-7 pt-5 offset-lg-3">
        <div class="col-12">
            <div class="row align-items-center mb-4 pb-1">
                <div class="col-12">
                    <div class="product_header">
                        <div class="product_header_left">
                            <div class="custom_select">
                                <select (change)="sort($event)" class="form-control form-control-sm">
                                    <option value="default">Ordenar por defecto</option>
                                    <option value="title">Ordenar por nombre</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="loaded&&products.length==0">
                <h3 class="text-center mt-5">No encontramos nada con esta búsqueda.</h3>
                <h5 class="text-center mt-5">Revisá la ortografía de las palabras</h5>
                <h5 class="text-center mt-5">Utilizá menos cantidad de palabras o más genéricas</h5>
            </div>

            <div *ngIf="products.length>0" class="row shop_container list">
                <div *ngFor="let product of products | paginate: { itemsPerPage: 10, currentPage: p }" class="col-lg-3 col-md-4 col-6">
                    <div style="align-items: center;" class="product">
                        <div *ngIf="product.imageUrl!='no-image'" class="product_img">
                            <a href="javascript:void(0)" [routerLink]="'../product/'+product.id">
                                <img  [src]="product.imageUrl" alt="product_img1" style="display: block; margin: auto;">
                            </a>
                        </div>
                        <div *ngIf="product.imageUrl=='no-image'" class="product_img">
                            <a href="javascript:void(0)" [routerLink]="'../product/'+product.id">
                                <img style="max-width: 540px; " src="../../../assets/images/gm-logo.png" alt="product_img1 ">
                            </a>
                        </div>
                        <div class="product_info">
                            <h6 class="product_title"><a href="javascript:void(0)" [routerLink]="'../product/'+product.id">{{product.title}}</a></h6>
                            <div class="product_price">
                                <span *ngIf="!product.clusterContainer&&product.minStock>0" class="price">${{product.price}}</span>
                             <div class="pr_desc">
                                <p>{{product.longDescription}}</p>
                            </div>
                            <div class="list_product_action_box mx-auto">
                                <ul class="list_none pr_action_btn">
                                    <li *ngIf="!product.clusterContainer&&product.published&&product.stock>product.minStock&&product.minStock>0" class="add-to-cart"><a href="javascript:void(0)" (click)="addToCart(product)"><i class="icon-basket-loaded"></i> Agregar al carrito</a></li>
                                    <li *ngIf="(!product.published||product.minStock==0||product.stock==0||product.stock<=product.minStock)&&!product.clusterContainer" class="add-to-cart"><a [href]="'https://api.whatsapp.com/send?phone=542235361844&text='+environment.baseUrl+'/product/'+product.id" target="_blank"><i class="icon-basket-loaded"></i> Consulte disponibilidad</a></li>
                                    <li *ngIf="product.clusterContainer&&product.published" class="add-to-cart"><a href="javascript:void(0)" [routerLink]="'../product/'+product.id"><i class="icon-basket-loaded"></i> Comprar</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>               
            </div>
            <div class="row justify-content-center mb-3">
                <pagination-controls class="center my-pagination" (pageChange)="pageChange($event)" autoHide="true" responsive="true" nextLabel="Siguiente" previousLabel="Anterior"></pagination-controls>
            </div>
        </div>
    </div>
    <!-- END SECTION SHOP -->
</div>
