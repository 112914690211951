<div style="min-height: 100vh;" class="custom-container">
    <div class="col-lg-7 pt-5 offset-lg-3">
        <h3 class="mt-5 mb-5">Contendores de menú</h3>
        <div class="col-md-12">
            <div class="form-group">
                <div class="row">
                    <div class="col-4">
                        <input type="text" [(ngModel)]="containerTitle" [ngModelOptions]="{standalone:true}" class="form-control" name="tags" placeholder="Título" autocomplete="off">
                    </div>
                    <div class="col-4">
                        <input type="text" [(ngModel)]="containerIcon" [ngModelOptions]="{standalone:true}" class="form-control" name="tagValue" placeholder="Ícono" autocomplete="off">
                    </div>
                    <div class="col-4">
                        <button type="button" class="btn btn-dark btn-radius" (click)="addContainer()" name="submit" value="Submit">Agregar</button>
                    </div>
                </div>
            </div>
            <div *ngIf="containersArray.length>0" style="margin-bottom: 10px;">
                <span class="erasable-span" *ngFor="let container of containersArray; index as i" (click)="deleteContainer(i)">{{container.title}} </span>
            </div>
        </div>

        <h3 class="mt-5 mb-5">Categorías</h3>
        <div class="col-md-12">
            <div class="form-group">
                <div class="row">
                    <div class="col-3">
                        <input type="text" [(ngModel)]="categoryTitle" [ngModelOptions]="{standalone:true}" class="form-control" name="tags" placeholder="Título" autocomplete="off">
                    </div>
                    <div class="col-3">
                        <input type="text" [(ngModel)]="categoryIcon" [ngModelOptions]="{standalone:true}" class="form-control" name="tagValue" placeholder="Ícono" autocomplete="off">
                    </div>
                    <div class="col-3">
                        <select class="form-control" [(ngModel)]="location" name="" id="">
                            <option disabled value="default">Ubicación</option>
                            <option value="main">Menú principal</option>
                            <option *ngFor="let option of containersArray" [value]="option.title">{{option.title}}</option>
                        </select>
                    </div>
                    <div class="col-3">
                        <button type="button" [disabled]="location=='default'||categoryTitle.length==0" class="btn btn-dark btn-radius" (click)="addCategory()" name="submit" value="Submit">Agregar</button>
                    </div>
                </div>
            </div>
            <div *ngIf="containersArray.length>0" style="margin-bottom: 10px;">
                <span class="erasable-span" *ngFor="let category of categoriesArray; index as i" (click)="deleteCategory(i)">{{category.title}} </span>
            </div>
        </div>
        <div class="col-12 mt-5">
            <a href="javascript:void(0)" class="btn btn-fill-out checkout mb-5" style="margin-right: 0px;" (click)="update()">Actualizar</a>
        </div>
    </div>
</div>