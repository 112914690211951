import { Component, OnInit } from '@angular/core';
import { OrderService } from 'src/app/services/order.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-shipping-values',
  templateUrl: './shipping-values.component.html',
  styleUrls: ['./shipping-values.component.css']
})
export class ShippingValuesComponent implements OnInit {

  public shippingValues = [];
  public _id;
  constructor(
    private orderService:OrderService
  ) { }

  ngOnInit(): void {
    this.orderService.getShippingValues().subscribe(
      resp=>{
        if (resp) {
          this.shippingValues = resp.data;
          this._id = resp._id;
        }
      },
      err=>console.log(err)
    )
  }

  removeShipping(i){
    this.shippingValues.splice(i,1);
  }

  addShipping(){
    let obj = {
      min:0,
      max:0,
      price:0
    }
    this.shippingValues.push(obj);
  }

  update(){
    let obj;
    if (this._id){
      obj = {
        _id:this._id,
        data: this.shippingValues
      }
    }else{
      obj = {
        data:this.shippingValues
      }
    }
    this.orderService.updateShippingValues(obj).subscribe(
      resp=>{
        Swal.fire({
          title:"Actualizado",
          icon:"success"
        })
      },
      err=>console.log(err)
    )
  }

}
