import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private http:HttpClient
  ) { }

  createOrder(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/orders/createOrder`, data);
  }

  updateOrder(data):Observable<any>{
    return this.http.put(`${environment.apiBaseUrl}/orders/updateOrder`, data);
  }

  deleteOrder(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/orders/deleteOrder`, data);
  }

  getShippingValues():Observable<any>{
    return this.http.get(`${environment.apiBaseUrl}/orders/getShippingValues`);
  }

  updateShippingValues(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/orders/updateShippingValues`, data);
  }

  getOrders():Observable<any>{
    return this.http.get(`${environment.apiBaseUrl}/orders/getOrders`);
  }

  getOrder(order):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/orders/getOrder`, order);
  }

  getUserOrders(user):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/orders/getUserOrders`, user);
  }

  createCreditCard(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/orders/createCreditCard`, data);
  }

  deleteCreditCard(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/orders/deleteCreditCard`, data);
  }

  getCreditCards():Observable<any>{
    return this.http.get(`${environment.apiBaseUrl}/orders/getCreditCards`);
  }

  createMpOrder(data, order):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/orders/createMpOrder`, {items:data, order:order});
  }

  getConfig():Observable<any>{
    return this.http.get(`${environment.apiBaseUrl}/orders/getConfig`)
  }

  updateConfig(data):Observable<any>{
    return this.http.post(`${environment.apiBaseUrl}/orders/updateConfig`, data)
  }

}
