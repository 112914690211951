
<div class="preloader">
  <div class="lds-ellipsis">
      <span></span>
      <span></span>
      <span></span>
  </div>
</div>
<header class="header_wrap">
    <div class="middle-header dark_skin">
        <div class="custom-container">
            <div class="nav_block">
                <a class="navbar-brand" [routerLink]="'main'">
                    <img class="logo_dark" src="assets/images/gm-logo.png" height="75px" alt="logo" />
                </a>
                <div class="product_search_form rounded_input show">
                    <form>
                        <div class="input-group">
                            <input [(ngModel)]="searchText" [ngModelOptions]="{standalone:true}" class="form-control" placeholder="Buscar producto..." required=""  type="text">
                            <button type="button" (click)="generalSearch()" class="search_btn2"><i class="lnr lnr-magnifier"></i></button>
                        </div>
                    </form>
                </div>
                <ul class="navbar-nav attr-nav align-items-center">
                    <li>
                        <a *ngIf="!user" href="javascript:void(0)" (click)="handleLogin()" class="nav-link"><i class="lnr lnr-user"></i><span> Login</span></a>
                        <a *ngIf="user" href="javascript:void(0)" class="nav-link"><span> {{user.userName}}</span></a>
                    </li>
                    <li class="dropdown cart_dropdown"><a class="nav-link cart_trigger" href="javascript:void(0)" data-toggle="dropdown"><i class="lnr lnr-cart"></i><span class="cart_count">{{getProducts.length}}</span></a>
                        <div class="cart_box cart_right dropdown-menu dropdown-menu-right">
                            <ul class="cart_list">
                                <li *ngFor="let product of getProducts; index as i">
                                    <a href="javascript:void(0)" (click)="deleteProduct(i)" class="item_remove"><i class="ion-close"></i></a>
                                    <a href="#"><img [src]="product.imageUrl" alt="cart_thumb1">{{product.title}}</a>
                                    <span class="cart_quantity"> {{product.qnt}} x <span class="cart_amount"> <span class="price_symbole">$</span></span>{{product.price}}</span>
                                </li>
                            </ul>
                            <div class="cart_footer">
                                <h5 class="text-center m-3" *ngIf="getProducts.length==0">Tu carrito se encuentra vacío</h5>
                                <p *ngIf="getProducts.length>0" class="cart_total"><strong>Subtotal:</strong> <span class="cart_price"> <span class="price_symbole">$</span></span>{{getTotal|number:'0.0-2'}}</p>
                                <p *ngIf="config&&getProducts.length>0" class="cart_buttons">
                                    <a *ngIf="config.activeCheckout" href="javascript:void(0)" (click)="handleCheckout()" class="btn btn-fill-out checkout">Abonar</a>
                                    <span *ngIf="!config.activeCheckout" href="javascript:void(0)" class="text-danger">Las ventas web se encuentran deshabilitadas por el momento</span>
                                </p>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="bottom_header dark_skin main_menu_uppercase border-top border-bottom">
        <div class="custom-container">
            <div class="row"> 
                <div class="col-lg-3 col-md-4 col-sm-6 col-3">
                    <div class="categories_wrap">
                        <button type="button" data-toggle="collapse" data-target="#navCatContent" aria-expanded="false" class="categories_btn">
                            <i class="lnr lnr-menu"></i><span>Categorías </span>
                        </button>
                        <div id="navCatContent" class="nav_cat navbar collapse">
                            <ul> 
                                <li *ngFor="let container of menu" class="dropdown dropdown-mega-menu">
                                    <a class="dropdown-item nav-link dropdown-toggler" href="#" data-toggle="dropdown"><i [class]="container.icon"></i> <span>{{container.title}}</span></a>
                                    <div class="dropdown-menu">
                                        <ul class="mega-menu d-lg-flex">
                                            <li class="mega-menu-col col-lg-12">
                                                <ul class="d-lg-flex">
                                                    <li *ngFor="let category of container.categories" class="mega-menu-col col-lg-6">
                                                        <ul> 
                                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" href="javascript:void(0)" [routerLink]="['viewProducts']"[queryParams]="{action:'category', value:category.title}">{{category.title}}</a></li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li *ngFor="let category of menuCategories">
                                    <a class="dropdown-item nav-link nav_item" (click)="hideMenu()" href="javascrip:void(0)" [routerLink]="['viewProducts']"[queryParams]="{action:'category', value:category.title}"><i [class]="category.icon"></i> <span>{{category.title}}</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-md-8 col-sm-6 col-9">
                    <nav class="navbar navbar-expand-lg">
                        <button class="navbar-toggler side_navbar_toggler" type="button" data-toggle="collapse" data-target="#navbarSidetoggle" aria-expanded="false"> 
                            <span class="ion-android-menu"></span>
                        </button>
                        <div class="collapse navbar-collapse mobile_side_menu" id="navbarSidetoggle">
                            <ul class="navbar-nav">
                                <li><a class="nav-link nav_item" [routerLink]="'main'" [routerLinkActive]="'active'">Principal</a></li> 
                                <li class="dropdown">
                                    <a data-toggle="dropdown" class="nav-link dropdown-toggle" href="#">Mi cuenta</a>
                                    <div class="dropdown-menu">
                                        <ul> 
                                            <li *ngIf="!user"><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'register'" [routerLinkActive]="'active'" href="javascript:void(0)">Registrarme</a></li>
                                            <li *ngIf="!user"><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" (click)="handleLogin()" href="javascript:void(0)">Ingresar</a></li>
                                            <li *ngIf="user"><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'myAccount'" [routerLinkActive]="'active'" href="javascript:void(0)">Mis datos</a></li>
                                            <li *ngIf="user"><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" href="javascript:void(0)" (click)="logout()">Cerrar sesión</a></li>
                                        </ul>
                                    </div>   
                                </li>
                                <li *ngIf="user&&user.role=='admin'"  class="dropdown">
                                    <a data-toggle="dropdown" class="nav-link dropdown-toggle" href="#">Administrador</a>
                                    <div class="dropdown-menu">
                                        <ul> 
                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'createProduct'" [routerLinkActive]="'active'">Crear producto</a></li>
                                        </ul>
                                        <ul> 
                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'editProducts'" [routerLinkActive]="'active'">Editar productos</a></li>
                                        </ul>
                                        <ul> 
                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'orders'" [routerLinkActive]="'active'">Pedidos/Configuración</a></li>
                                        </ul>
                                        <ul> 
                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'editBanners'" [routerLinkActive]="'active'">Editar banners</a></li>
                                        </ul>
                                        <ul> 
                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'editCategories'" [routerLinkActive]="'active'">Editar menú de categorias</a></li>
                                        </ul>
                                        <ul> 
                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'shippingConfig'" [routerLinkActive]="'active'">Fletes</a></li>
                                        </ul>
                                        <ul> 
                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'users'" [routerLinkActive]="'active'">Usuarios</a></li>
                                        </ul>
                                        <ul> 
                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'cards'" [routerLinkActive]="'active'">Tarjetas de crédito</a></li>
                                        </ul>
                                        <ul> 
                                            <li><a class="dropdown-item nav-link nav_item" (click)="hideMenu()" [routerLink]="'generator'" [routerLinkActive]="'active'">Generador de QR</a></li>
                                        </ul>
                                    </div>   
                                </li>
                                <li><a class="nav-link nav_item" [routerLink]="'about'" [routerLinkActive]="'active'">Quiénes somos</a></li> 
                            </ul>
                        </div>
                        <div class="contact_phone contact_support">
                            <a href="https://api.whatsapp.com/send?phone=542235361844" target="_blank">
                                <span> <img src="assets/images/wapp_logo.png" width="50" alt=""> 2235361844</span>
                            </a>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>


<!--MAIN SECTION-->

<div class="main_content">
  <router-outlet></router-outlet>
</div>


<!-- START FOOTER -->
<footer class="footer_dark">
	<div class="footer_top">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-4 col-sm-12">
                	<div class="widget">
                        <div class="footer_logo">
                            <a href="#"><img height="70" src="assets/images/gm-logo-bw.png" alt="logo"/></a>
                        </div>
                    </div>
                    <div class="widget">
                        <ul class="social_icons social_white">
                            <li><a href="https://www.facebook.com/gmsanitarios"><i class="ion-social-facebook"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCAq_3JrX7ZJIXf47dWXNALA"><i class="ion-social-youtube-outline"></i></a></li>
                            <li><a href="https://www.instagram.com/sanitariosgm/"><i class="ion-social-instagram-outline"></i></a></li>
                        </ul>
                    </div>
        		</div>
                <div class="col-md-4 col-sm-6">
                	<div class="widget">
                        <h6 class="widget_title">Links útiles</h6>
                        <ul class="widget_links">
                            <li><a href="/">Home</a></li>
                            <li><a *ngIf="user" href="/myAccount">Mi cuenta</a></li>
                            <li><a href="/viewProducts?action=category&value=Calderas">Calderas</a></li>
                            <li><a href="/viewProducts?action=category&value=Presurizadoras%20Tanque">Presurizadoras tanque</a></li>
                            <li><a href="/viewProducts?action=category&value=Bachas%20de%20Acero">Bachas de acero</a></li>
                            <li><a href="/viewProducts?action=category&value=Griferías%20FV">Griferias FV</a></li>
                        </ul>
                    </div>
                </div>
                   <div class="col-md-4 col-sm-6">
                	<div class="widget">
                        <h6 class="widget_title">Información de contacto</h6>
                        <ul class="contact_info contact_info_light">
                            <li>
                                <i class="ti-location-pin"></i>
                                <a href="https://goo.gl/maps/UtCXYcnkz9KFi7Yf7" target="_blank">Av Fortunato de la plaza 3455, Mar del Plata</a>
                            </li>
                            <li>
                                <i class="ti-email"></i>
                                <a href="mailto:ventas@gmsanitarios.com.ar">ventas@gmsanitarios.com.ar</a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bottom_footer border-top-tran">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <p class="mb-md-0 text-center text-md-left">© 2023 GM sanitarios</p>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- END FOOTER -->
<a (click)="scrollUp()"class="scrollup" style="display: none;"><i class="lnr lnr-chevron-up"></i></a> 


<!--Login Modal-->
<div class="modal fade" id="loginModal" tabindex="-1" role="dialog" aria-labelledby="loginModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h3 class="modal-title" id="exampleModalLabel">Login</h3>
          <button type="button" class="close" data-dismiss="modal" (click)="restoring?restoring=false:''" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="col-12 p-5">
                <form *ngIf="!restoring" [formGroup]="userForm">
                    <div class="form-group">
                        <input type="text" formControlName="email" required="" class="form-control" name="email" placeholder="Email">
                    </div>
                    <div class="form-group">
                        <input class="form-control" formControlName="password" required="" type="password" name="password" placeholder="Contraseña">
                    </div>
                    <div class="login_footer form-group">
                        <div class="chek-form">
                            <div class="custome-checkbox">
                                <input class="form-check-input" formControlName="rememberMe" type="checkbox" name="checkbox" id="exampleCheckbox1" value="">
                                <label class="form-check-label" for="exampleCheckbox1"><span>Recordarme</span></label>
                            </div>
                        </div>
                        <a href="javascript:void(0)" (click)="restoring = true">¿Olvidaste tu password?</a>
                    </div>
                    <div class="form-group">
                        <button type="button" (click)="login()" class="btn btn-fill-out btn-block" name="login">Ingresar</button>
                    </div>
                </form>
                <div *ngIf="restoring" class="form-group">
                    <div class="form-group">
                        <input type="email" [(ngModel)]="restoringEmail" class="form-control" name="email" placeholder="Email">
                    </div>
                    <div class="form-group">
                        <button type="button" [disabled]="restoringEmail.length<3" (click)="restorePassword()" class="btn btn-fill-out btn-block" name="login">Recuperar contraseña</button>
                    </div>
                </div>
                <div *ngIf="!restoring" class="form-note text-center">¿No tenés una cuenta? <a [routerLink]="'register'" data-dismiss="modal">Registrate</a></div>
            </div>
        </div>
      </div>
    </div>
  </div>