
<div class="col-md-7 pt-5 offset-md-3">
    <div class="login_register_wrap section">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-xl-6 col-md-10">
                    <div class="login_wrap">
                        <div class="padding_eight_all bg-white">
                            <div>
                                <h3 *ngIf="updated">Tu contraseña ha sido modificada</h3>
                            </div>
                            <div class="form-note text-center"><a [routerLink]="'main'">Volver</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>