import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public userForm = this.fb.group({
    userName:['', Validators.required],
    phone:['', Validators.required],
    email:['', Validators.email],
    confirmPassword:['', Validators.required],
    password:['', Validators.required]
  })

  constructor(
    private fb:FormBuilder,
    private userService:UserService
  ) { }

  ngOnInit(): void {
  }

  submit(){
    if(this.userForm.value.password==this.userForm.value.confirmPassword){
      this.userService.register(this.userForm.value).subscribe(
        resp=>{
          Swal.fire({
            icon:"success",
            title:"Usuario Creado"
          }).then(
            resp=>window.location.reload()
          )
        },
        err=>{
          console.log(err)
          Swal.fire({
            icon:"error",
            title:err.error.msg
          })
        }
      )
    }else{
      Swal.fire({
        icon:"info",
        title:"Revisar contraseña"
      })
    }
  }

  

}
